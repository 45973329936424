import { Layout } from 'antd'

const Footer = () => {
  return (
    <Layout.Footer style={{ textAlign: 'center' }}>
      Copyright © Ek-Chai Distribution System Co., Ltd. {new Date().getFullYear()}. All Rights
      Reserved.
    </Layout.Footer>
  )
}

export default Footer
