export const formatNumber = (n, frac = 0, max = 2) => {
  const intl = new Intl.NumberFormat('en-US', {
    minimumFractionDigits: frac,
    maximumFractionDigits: max,
  })
  return intl.format(n)
}

export const convertOffsetToPage = (offset, limit) => {
  return (offset + limit) / limit
}

export const sorting = (array: any, sortField: string, order: 'asc' | 'desc' = 'asc') => {
  if (Array.isArray(array) && array.length) {
    return array.sort((a, b) => {
      return order === 'desc' ? b[sortField] - a[sortField] : a[sortField] - b[sortField]
    })
  }
  return array
}

export const mergeStringArray = (stringArray: any = [], format: string = ' ') => {
  if (Array.isArray(stringArray)) {
    return stringArray.filter(Boolean).join(format)
  }
  return ''
}

export const formatPhoneNumber = (phone: string = '') => {
  return phone.replace(/\D+/g, '').replace(/(\d{3})(\d{3})(\d{4})/, '$1-$2-$3')
}

export const getFileExtension = (filename: string) => {
  const ext = filename.split('.').pop()
  return ext?.toLocaleLowerCase()
}
