import './Select.scss'
import { Select, Spin } from 'antd'
import type { SelectProps } from 'antd/es/select'
import debounce from 'lodash/debounce'
import React, { useEffect, useMemo, useRef, useState } from 'react'
import { SearchOutlined, CaretDownOutlined } from '@ant-design/icons'
import { getCorporates } from 'services/corporate.service'

export interface DebounceSelectProps<ValueType = any>
  extends Omit<SelectProps<ValueType | ValueType[]>, 'options' | 'children'> {
  fetchOptions: (search: string | undefined) => Promise<ValueType[]>
  debounceTimeout?: number
  prefixIcon?: React.ReactNode | undefined
}

function DebounceSelect<
  ValueType extends { key?: string; label: React.ReactNode; value: string | number } = any,
>({
  fetchOptions,
  debounceTimeout = 800,
  prefixIcon,
  placeholder,
  ...props
}: DebounceSelectProps<ValueType>) {
  const [fetching, setFetching] = useState(false)
  const [options, setOptions] = useState<ValueType[]>([])
  const fetchRef = useRef(0)

  const debounceFetcher = useMemo(() => {
    const loadOptions = (value: string) => {
      fetchRef.current += 1
      const fetchId = fetchRef.current
      setOptions([])
      setFetching(true)

      fetchOptions(value).then((newOptions) => {
        if (fetchId !== fetchRef.current) {
          // for fetch callback order
          return
        }

        setOptions(newOptions)
        setFetching(false)
      })
    }

    return debounce(loadOptions, debounceTimeout)
  }, [fetchOptions, debounceTimeout])

  useEffect(() => {
    fetchOptions(undefined).then((newOptions) => {
      setOptions(newOptions)
    })
  }, [])

  return (
    <div className='lotus-select'>
      {prefixIcon && <div className='lotus-select-prefix-icon'>{prefixIcon}</div>}
      <Select
        labelInValue
        filterOption={false}
        onSearch={debounceFetcher}
        suffixIcon={<CaretDownOutlined />}
        notFoundContent={fetching ? <Spin size='small' /> : null}
        {...props}
        options={options}
        placeholder={placeholder}
        allowClear
        showSearch
      />
      <div className='lotus-select-placeholder'>{placeholder}</div>
    </div>
  )
}

// Usage of DebounceSelect
interface SelectedValue {
  label: string
  value: string
}

async function fetchCorporate(keyword: string | undefined = undefined): Promise<SelectedValue[]> {
  let response = []
  if (keyword === '') {
    keyword = undefined
  }
  const payload = await getCorporates({ offset: 0, limit: 10, keyword })
  if (payload?.status.code === 200) {
    response = payload.data.map((item: any) => ({
      key: item._id,
      label: `${item.corporateNumber} ${item.corporateName?.th}`,
      value: JSON.stringify(item),
    }))
  }

  return response
}

const SelectCorporate = ({ onChange }: { onChange?: (value: any) => void }) => {
  const [value, setValue] = useState<SelectedValue>()

  return (
    <DebounceSelect
      value={value}
      size='large'
      placeholder='Corporate info.'
      fetchOptions={fetchCorporate}
      onChange={(newValue: any) => {
        setValue(newValue as SelectedValue)
        typeof onChange === 'function' && onChange(JSON.parse(newValue.value))
      }}
      prefixIcon={<SearchOutlined />}
      className={`w-full ${value && 'lotus-select-has-value'}`}
    />
  )
}

export default SelectCorporate
