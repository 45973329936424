import { Button, Modal } from 'antd'
import { InfoCircleOutlined, WarningOutlined, CheckCircleOutlined } from '@ant-design/icons'

interface IProps {
  title: string | React.ReactNode
  message: string | React.ReactNode
  status: 'error' | 'warning' | 'success'
  icon?: React.ReactNode
  width?: number
  isOpen: boolean
  okText?: string
  cancelText?: string
  onOk: () => void
  onCancel?: () => void
}

const Popup = ({
  title,
  message,
  status,
  icon,
  width = 520,
  isOpen,
  okText = 'ตกลง',
  cancelText = 'ยกเลิก',
  onOk,
  onCancel,
}: IProps) => {
  const isOnCencel = () => !!(typeof onCancel === 'function')
  return (
    <Modal
      open={isOpen}
      footer={null}
      width={width}
      closable={false}
      maskClosable={true}
      wrapClassName='rounded-lg'
      centered
    >
      <div className='text-center p-2'>
        <div className='text-5xl mb-4'>
          {icon
            ? icon
            : (() => {
                switch (status) {
                  case 'error':
                    return <InfoCircleOutlined className='text-[#ff4d4f]' />
                  case 'warning':
                    return <WarningOutlined className='text-[#faad14]' />
                  case 'success':
                    return <CheckCircleOutlined className='text-primary' />
                }
              })()}
        </div>
        <div className='text-xl font-bold'>{title}</div>
        <div className='text-base text-secondary-500'>{message}</div>
        <div className='flex gap-2 justify-around mt-4'>
          {isOnCencel() && (
            <Button type='default' size='large' onClick={onCancel}>
              {cancelText}
            </Button>
          )}
          <Button type='primary' size='large' block={isOnCencel() ? false : true} onClick={onOk}>
            {okText}
          </Button>
        </div>
      </div>
    </Modal>
  )
}

export default Popup
