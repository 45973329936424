import { RoleTypeEnum } from 'enums/role'

export const ROLE_ACCESS = {
  ALL: Object.values(RoleTypeEnum),
  REPORT: [
    RoleTypeEnum.IT_ADMIN,
    RoleTypeEnum.RS_SUPPORT,
    RoleTypeEnum.RS_WL3,
    RoleTypeEnum.RS_ADMIN,
  ],
  ORDER_LIST: {
    CREATE: [
      RoleTypeEnum.IT_ADMIN,
      RoleTypeEnum.RS_SUPPORT,
      RoleTypeEnum.RS_WL3,
      RoleTypeEnum.RS_ADMIN,
    ],
    SUBMIT_PAYMENT_APPROVED: [
      RoleTypeEnum.IT_ADMIN,
      RoleTypeEnum.RS_SUPPORT,
      RoleTypeEnum.RS_ADMIN,
    ],
    APPROVE_ORDER_REVIEWED: [RoleTypeEnum.IT_ADMIN, RoleTypeEnum.RS_WL3],
  },
  ORDER_DETAIL: {
    EDIT: [
      RoleTypeEnum.IT_ADMIN,
      RoleTypeEnum.RS_SUPPORT,
      RoleTypeEnum.RS_WL3,
      RoleTypeEnum.RS_ADMIN,
    ],
    DOWNLOAD_QUOTATION: [
      RoleTypeEnum.IT_ADMIN,
      RoleTypeEnum.RS_SUPPORT,
      RoleTypeEnum.RS_WL3,
      RoleTypeEnum.RS_ADMIN,
    ],
    CONFIRM_PAYMENT: [
      RoleTypeEnum.IT_ADMIN,
      RoleTypeEnum.RS_SUPPORT,
      RoleTypeEnum.RS_WL3,
      RoleTypeEnum.RS_ADMIN,
    ],
    UPLOAD_PAYMENT: [
      RoleTypeEnum.IT_ADMIN,
      RoleTypeEnum.RS_SUPPORT,
      RoleTypeEnum.RS_WL3,
      RoleTypeEnum.RS_ADMIN,
    ],
    APPROVE_PAYMENT: [RoleTypeEnum.IT_ADMIN, RoleTypeEnum.ACCOUNT_1],
    UPLOAD_INVOICE: [RoleTypeEnum.IT_ADMIN, RoleTypeEnum.ACCOUNT_2],
    APPROVE_INVOICE: [RoleTypeEnum.IT_ADMIN, RoleTypeEnum.ACCOUNT_2],
    UPDATE_CARD_STATUS: [
      RoleTypeEnum.IT_ADMIN,
      RoleTypeEnum.RS_SUPPORT,
      RoleTypeEnum.RS_WL3,
      RoleTypeEnum.RS_ADMIN,
    ],
    UPLOAD_RECEIPT: [
      RoleTypeEnum.IT_ADMIN,
      RoleTypeEnum.RS_ADMIN,
      RoleTypeEnum.RS_SUPPORT,
      RoleTypeEnum.RS_WL3,
    ],
  },
}
