import { Button, Form, Modal } from 'antd'
import type { FormInstance } from 'antd/es/form/Form'
import { ExclamationCircleFilled } from '@ant-design/icons'
import { useCorporateStore } from 'stores/corporate'
import { useOrderStore } from 'stores/order'
import { useNavigate } from 'react-router-dom'

import ContentPanel from 'components/common/panel/PanalContent'
import SelectCorporate from 'components/common/select/SelectCorporate'
import { OrderStepTypeEnum } from 'enums/order'

const { confirm } = Modal
interface IProps {
  form: FormInstance
}

const StepSelectCorporate = ({ form }: IProps) => {
  const navigate = useNavigate()
  const { setOrder, setCurrentStep } = useOrderStore()
  const { corporate, setCorporate } = useCorporateStore()

  const handleChange = (value) => {
    form.resetFields(['productCatalogId', 'productCatalogType', 'orderItems', 'freebieItems'])
    form.setFieldValue('corporateId', value._id)
    form.setFieldValue('isAcceptFreebie', true)
    setCorporate(value)
  }

  const onNextStep = async () => {
    const formValue = await form.validateFields(['corporateId'])
    if (formValue) {
      setOrder({
        ...formValue,
        billingAddress: {
          streetAddress1: corporate?.streetAddress1,
          streetAddress2: corporate?.streetAddress2,
          subdistrict: corporate?.subdistrict,
          district: corporate?.district,
          province: corporate?.province,
          postalCode: corporate?.postalCode,
        },
      })
      setCurrentStep(OrderStepTypeEnum.STEP_ORDER)
    }
  }

  const backToOrderPage = () => {
    if (!form.getFieldValue('corporateId')) {
      navigate('/orders')
      return
    }

    confirm({
      title: 'Do you Want to back to order list page?',
      icon: <ExclamationCircleFilled />,
      onOk() {
        setOrder(null)
        navigate('/orders')
      },
    })
  }

  return (
    <>
      <ContentPanel title='Select Corporate'>
        <Form.Item
          name='corporateId'
          rules={[{ required: true, message: 'please select corporate' }]}
        >
          <SelectCorporate onChange={handleChange} />
        </Form.Item>
      </ContentPanel>

      <Form.Item shouldUpdate>
        {({ getFieldValue }) => (
          <div className='mt-10 flex gap-2 justify-center'>
            <Button type='default' size='large' onClick={backToOrderPage}>
              Back
            </Button>

            <Button
              type='primary'
              size='large'
              onClick={onNextStep}
              disabled={!getFieldValue('corporateId')}
            >
              Next
            </Button>
          </div>
        )}
      </Form.Item>
    </>
  )
}

export default StepSelectCorporate
