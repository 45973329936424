import { create } from 'zustand'
import { ProductCatalog } from './types/productCatalog.types'

type State = {
  catalog: ProductCatalog | null
  setCatalog: (catalog: ProductCatalog | null) => void

  productCatalogs: ProductCatalog[]
  setProductCatalogs: (catalog: ProductCatalog[]) => void
}

export const useProductCatalogStore = create<State>((set) => ({
  catalog: null,
  setCatalog: (catalog) =>
    set(() => ({
      catalog: catalog,
    })),

  productCatalogs: [],
  setProductCatalogs: (productCatalogs) =>
    set(() => ({
      productCatalogs: productCatalogs,
    })),
}))
