import fetch from '../utilities/fetchAxios'

export const getCorporates = async (query) => {
  const method = 'GET'
  const path = '/corporate/v1/corporates'
  return fetch(method, path, query)
}

export const getCorporateById = async (id) => {
  const method = 'GET'
  const path = `/corporate/v1/corporates/${id}`
  return fetch(method, path)
}
