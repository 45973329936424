import { useEffect, useState } from 'react'
import { Row, Col, Button, Tooltip } from 'antd'
import { useOrderStore } from 'stores/order'
import { useAppConfigStore } from 'stores/global'
import { OrderActivity } from 'stores/types/order.types'
import { getOrderActivity } from 'services/orderActivity.service'
import { exportQuotation, updateOrderStatus } from 'services/order.service'

import { formatNumber, getFileExtension } from 'utilities/common'
import { summary } from 'utilities/calculate'
import {
  isAllowUploadPayment,
  isAllowConfirmPayment,
  isAllowApprovePayment,
  isAllowDownLoadQuotation,
  isAllowEditUploadPayment,
  isAllowUploadInvoice,
  isAllowEditInvoice,
  isAllowApproveInvoice,
  isAllowUpdateCardStatus,
  isAllowEditReceipt,
  isAllowUploadReceipt,
} from 'utilities/rules'
import { ROLE_ACCESS } from 'constants/accessRole'
import { OrderStatusTypeEnum } from 'enums/order'

import dayjs from 'dayjs'
import ContentWraper from '../components/ContentWraper'
import PermissionAccess from 'components/PermissionAccess'
import ModalExportQuotation from '../components/ModalExportQuotation'
import ModalPaymentApproval from '../components/ModalPaymentApproval'
import ModalApprove from '../../../common/modal/ModalApprove'
import ModalReject from '../../../common/modal/ModalReject'
import ModalUploadPayment from '../upload/ModalUploadPayment'
import Popup from 'components/common/popup/Popup'
import ModalUploadInvoice from '../upload/ModalUploadInvoice'
import ModalInvoiceApproval from '../components/ModalInvoiceApproval'
import ModalUpdateCardStatus from '../components/ModalUpdateCardStatus'
import ModalUploadReceipt from '../upload/ModalUploadReceipt'

interface IProps {
  orderId: string
}

const OrderActivities = ({ orderId }: IProps) => {
  const { order } = useOrderStore()
  const { appConfig } = useAppConfigStore()

  const [activities, setActivities] = useState<OrderActivity[]>([])
  const [loading, setLoading] = useState<boolean>(false)

  const [isOpenExportQuotation, setIsOpenExportQuotation] = useState<boolean>(false)
  const [isLoadingExportQuotation, setIsLoadingExportQuotation] = useState<boolean>(false)

  const [isOpenPaymentApproval, setIsOpenPaymentApproval] = useState<boolean>(false)
  const [isOpenPaymentApproveModal, setIsOpenPaymentApproveModal] = useState<boolean>(false)
  const [isOpenPaymentRejectModal, setIsOpenPaymentRejectModal] = useState<boolean>(false)

  const [isOpenUploadPayment, setIsOpenUploadPayment] = useState<boolean>(false)
  const [isOpenUploadPaymentSuccess, setIsOpenUploadPaymentSuccess] = useState<boolean>(false)
  const [isOpenUploadPaymentFailed, setIsOpenUploadPaymentFailed] = useState<boolean>(false)

  const [isOpenUploadInvoice, setIsOpenUploadInvoice] = useState<boolean>(false)
  const [isOpenUploadInvoiceSuccess, setIsOpenUploadInvoiceSuccess] = useState<boolean>(false)
  const [isOpenUploadInvoiceFailed, setIsOpenUploadInvoiceFailed] = useState<boolean>(false)

  const [isOpenInvoiceApproval, setIsOpenInvoiceApproval] = useState<boolean>(false)
  const [isOpenInvoiceApproveModal, setIsOpenInvoiceApproveModal] = useState<boolean>(false)
  const [isOpenInvoiceRejectModal, setIsOpenInvoiceRejectModal] = useState<boolean>(false)

  const [isOpenUpdateCardStatus, setIsOpenUpdateCardStatus] = useState<boolean>(false)
  const [isOpenUpdateCardStatusSuccess, setIsOpenUpdateCardStatusSuccess] = useState<boolean>(false)

  const [isOpenUploadReceipt, setIsOpenUploadReceipt] = useState<boolean>(false)
  const [isOpenUploadReceiptSuccess, setIsOpenUploadReceiptSuccess] = useState<boolean>(false)
  const [isOpenUploadReceiptFailed, setIsOpenUploadReceiptFailed] = useState<boolean>(false)

  useEffect(() => {
    findOrderActivity()
  }, [])

  const findOrderActivity = async () => {
    try {
      const payload = await getOrderActivity(orderId)
      if (payload.status?.code === 200) {
        setActivities(payload.data)
      }
    } catch (error) {
      console.log(error)
    }
  }

  const downloadQuotation = async (lang: string) => {
    const query = {
      orderId: order?._id,
    }
    const headers = {
      'accept-language': lang,
    }
    const filename = `ใบเสนอราคา-${order?.productCatalogType.toLocaleLowerCase()}-${
      order?.orderNumber
    }-${dayjs(order?.createdAt).format('DD-MM-YYYY')}.pdf`
    setIsLoadingExportQuotation(true)
    const response = await exportQuotation(query, headers)
    const url = URL.createObjectURL(new Blob([response.data]))
    const link = document.createElement('a')
    link.href = url
    link.setAttribute('download', filename)
    document.body.appendChild(link)
    link.click()

    setIsLoadingExportQuotation(false)
    setIsOpenExportQuotation(false)
  }

  const onUpdateOrderStatus = async (status: OrderStatusTypeEnum, remark?: string) => {
    setLoading(true)
    const payload = {
      orderIds: [order?._id],
      statusToChange: status,
      remark: remark || undefined,
    }
    const response = await updateOrderStatus(payload)
    setLoading(false)
    return response
  }

  const getConfirmOrApprovePaymentStatus = () => {
    let statusToChange = OrderStatusTypeEnum.PAYMENT_CONFIRMED
    if (order?.status === OrderStatusTypeEnum.PAYMENT_SUBMITTED) {
      statusToChange = OrderStatusTypeEnum.PAYMENT_CONFIRMED
    } else if (order?.status === OrderStatusTypeEnum.PAYMENT_CONFIRMED) {
      statusToChange = OrderStatusTypeEnum.PAYMENT_APPROVED
    }
    return statusToChange
  }

  return (
    <div className='bg-white p-6'>
      <div className='py-4 px-10 bg-secondary-50 flex justify-between items-center'>
        <div className='w-4/5  m-auto flex justify-between items-center text-base'>
          <div className='font-bold'>ยอดที่ต้องได้รับ</div>
          <div className='font-bold'>
            THB {formatNumber(summary(order?.purchaseTotal || 0, order?.customizeTotal || 0), 2)}
          </div>
        </div>
      </div>

      <ContentWraper>
        <div className='w-4/5 m-auto'>
          <div className='mb-5 font-bold text-xl'>รายละเอียด</div>
          <Row
            gutter={16}
            align='middle'
            className='border border-solid border-secondary rounded-lg p-4 text-secondary-500'
          >
            <Col span={6}>Status</Col>
            <Col span={18}>{appConfig.orderStatusEnum[order?.status as string]}</Col>
            <Col offset={6} span={18}>
              <div className='flex gap-4 mt-4'>
                {!!isAllowConfirmPayment(order?.status) && (
                  <PermissionAccess roles={ROLE_ACCESS.ORDER_DETAIL.CONFIRM_PAYMENT}>
                    <Button
                      type='primary'
                      size='large'
                      onClick={() => setIsOpenPaymentApproval(true)}
                    >
                      ตรวจสอบการชำระเงิน
                    </Button>
                  </PermissionAccess>
                )}

                {!!isAllowApprovePayment(order?.status) && (
                  <PermissionAccess roles={ROLE_ACCESS.ORDER_DETAIL.APPROVE_PAYMENT}>
                    <Button
                      type='primary'
                      size='large'
                      onClick={() => setIsOpenPaymentApproval(true)}
                    >
                      ตรวจสอบการชำระเงิน
                    </Button>
                  </PermissionAccess>
                )}

                {!!isAllowUploadPayment(order?.status) && (
                  <PermissionAccess roles={ROLE_ACCESS.ORDER_DETAIL.UPLOAD_PAYMENT}>
                    <Button
                      type={isAllowEditUploadPayment(order?.status) ? 'default' : 'primary'}
                      size='large'
                      onClick={() => setIsOpenUploadPayment(true)}
                    >
                      {isAllowEditUploadPayment(order?.status)
                        ? 'แก้ไขหลักฐานการชำระเงิน'
                        : 'แจ้งการชำระเงิน'}
                    </Button>
                  </PermissionAccess>
                )}

                {!!isAllowDownLoadQuotation(order?.status) && (
                  <PermissionAccess roles={ROLE_ACCESS.ORDER_DETAIL.DOWNLOAD_QUOTATION}>
                    <Button
                      type='default'
                      size='large'
                      onClick={() => setIsOpenExportQuotation(true)}
                    >
                      ออกใบเสนอราคา
                    </Button>
                  </PermissionAccess>
                )}

                {!!isAllowUploadInvoice(order?.status) && (
                  <PermissionAccess roles={ROLE_ACCESS.ORDER_DETAIL.UPLOAD_INVOICE}>
                    <Button
                      type={isAllowEditInvoice(order?.status) ? 'default' : 'primary'}
                      size='large'
                      onClick={() => setIsOpenUploadInvoice(true)}
                    >
                      {isAllowEditInvoice(order?.status)
                        ? 'แก้ไขเอกสารใบแจ้งหนี้'
                        : 'อัปโหลดใบแจ้งหนี้'}
                    </Button>
                  </PermissionAccess>
                )}

                {!!isAllowApproveInvoice(order?.status) && (
                  <PermissionAccess roles={ROLE_ACCESS.ORDER_DETAIL.APPROVE_INVOICE}>
                    <Button
                      type='primary'
                      size='large'
                      onClick={() => setIsOpenInvoiceApproval(true)}
                    >
                      ตรวจสอบใบแจ้งหนี้
                    </Button>
                  </PermissionAccess>
                )}

                {!!isAllowUpdateCardStatus(order?.status) && (
                  <PermissionAccess roles={ROLE_ACCESS.ORDER_DETAIL.UPDATE_CARD_STATUS}>
                    <Button
                      type='primary'
                      size='large'
                      onClick={() => setIsOpenUpdateCardStatus(true)}
                    >
                      อัปเดตสถานะ
                    </Button>
                  </PermissionAccess>
                )}

                {!!isAllowUploadReceipt(order?.status) && (
                  <PermissionAccess roles={ROLE_ACCESS.ORDER_DETAIL.UPLOAD_RECEIPT}>
                    <Button
                      type={isAllowEditReceipt(order?.status) ? 'default' : 'primary'}
                      size='large'
                      onClick={() => setIsOpenUploadReceipt(true)}
                    >
                      {isAllowEditReceipt(order?.status)
                        ? 'แก้ไขเอกสารใบเสร็จรับเงิน'
                        : 'อัปโหลดใบเสร็จรับเงิน'}
                    </Button>
                  </PermissionAccess>
                )}
              </div>
            </Col>
          </Row>
        </div>
      </ContentWraper>

      <ContentWraper>
        <div className='w-4/5 m-auto'>
          <div className='mb-5 font-bold text-xl'>ไฟล์ที่อัปโหลดแล้ว</div>
          {/* <div className='flex justify-end my-2'>
            <Button type='link' className='p-0'>
              Upload
            </Button>
            <div className='border-r border-solid border-secondary-300 m-2'></div>
            <Button type='link' className='p-0'>
              Edit
            </Button>
          </div> */}
          <div className='border border-solid border-secondary rounded-lg pb-1'>
            <div className='bg-secondary-50 p-4 text-secondary-500'>
              <Row gutter={16} className='font-semibold'>
                <Col span={12}>Files uploaded</Col>
                <Col span={4}>Date</Col>
                <Col span={4}>User</Col>
                <Col span={4}>Type</Col>
              </Row>
            </div>

            <div className='bg-white p-4 text-secondary-500'>
              {!!order?.payment?.files?.length &&
                order?.payment?.files?.map((item, index) => {
                  return (
                    <Row key={`payment-${index}`} gutter={16} align='middle' className='my-2'>
                      <Col span={12}>
                        <div className='flex gap-2 truncate'>
                          <img
                            src={`/assets/images/icons/icon-${getFileExtension(
                              item?.originalFileName,
                            )}.svg`}
                            loading='lazy'
                            alt='reciept file'
                          />
                          <Tooltip title={item?.originalFileName}>
                            <a
                              href={item?.url}
                              target='_blank'
                              rel='noopener noreferrer'
                              className='text-primary underline'
                            >
                              {item?.originalFileName}
                            </a>
                          </Tooltip>
                        </div>
                      </Col>
                      <Col span={4}>
                        <div className=''>{dayjs(item?.createdAt).format('DD-MM-YYYY')}</div>
                        <div className=''>{dayjs(item?.createdAt).format('HH:mm')}</div>
                      </Col>
                      <Col span={4}>
                        <div className='font-semibold'>{item?.createdBy?.name}</div>
                        <div className='uppercase tracking-wide'>{item?.createdBy?.employeeId}</div>
                      </Col>
                      <Col span={4}>หลักฐานการชำระเงิน</Col>
                    </Row>
                  )
                })}

              {!!order?.information?.invoice && (
                <Row
                  gutter={16}
                  className='py-2 border-t border-solid border-gray-300'
                  align='middle'
                >
                  <Col span={12}>
                    <div className='flex gap-2'>
                      <img
                        src={`/assets/images/icons/icon-${getFileExtension(
                          order?.information?.invoice?.originalFileName,
                        )}.svg`}
                        loading='lazy'
                        alt='invoice file'
                      />
                      <Tooltip title='Invoice File'>
                        <a
                          href={order?.information?.invoice?.url}
                          target='_blank'
                          rel='noopener noreferrer'
                          className='text-primary underline'
                        >
                          {order?.information?.invoice?.originalFileName}
                        </a>
                      </Tooltip>
                    </div>
                  </Col>
                  <Col span={4}>
                    <div className=''>
                      {dayjs(order?.information?.invoice?.createdAt).format('DD-MM-YYYY')}
                    </div>
                    <div className=''>
                      {dayjs(order?.information?.invoice?.createdAt).format('HH:mm')}
                    </div>
                  </Col>
                  <Col span={4}>
                    <div className='font-semibold'>
                      {order?.information?.invoice?.createdBy?.name}
                    </div>
                    <div className='uppercase tracking-wide'>
                      {order?.information?.invoice?.createdBy?.employeeId}
                    </div>
                  </Col>
                  <Col span={4}>ใบแจ้งหนี้</Col>
                </Row>
              )}

              {!!order?.information?.receipt && (
                <Row
                  gutter={16}
                  className='pt-2 border-t border-solid border-gray-300'
                  align='middle'
                >
                  <Col span={12}>
                    <div className='flex gap-2'>
                      <img
                        src={`/assets/images/icons/icon-${getFileExtension(
                          order?.information?.receipt?.originalFileName,
                        )}.svg`}
                        loading='lazy'
                        alt='reciept file'
                      />
                      <Tooltip title='Receipt File'>
                        <a
                          href={order?.information?.receipt?.url}
                          target='_blank'
                          rel='noopener noreferrer'
                          className='text-primary underline'
                        >
                          {order?.information?.receipt?.originalFileName}
                        </a>
                      </Tooltip>
                    </div>
                  </Col>
                  <Col span={4}>
                    <div className=''>
                      {dayjs(order?.information?.receipt?.createdAt).format('DD-MM-YYYY')}
                    </div>
                    <div className=''>
                      {dayjs(order?.information?.receipt?.createdAt).format('HH:mm')}
                    </div>
                  </Col>
                  <Col span={4}>
                    <div className='font-semibold'>
                      {order?.information?.receipt?.createdBy?.name}
                    </div>
                    <div className='uppercase tracking-wide'>
                      {order?.information?.receipt?.createdBy?.employeeId}
                    </div>
                  </Col>
                  <Col span={4}>ใบเสร็จรับเงิน</Col>
                </Row>
              )}
            </div>
          </div>
        </div>
      </ContentWraper>

      <ContentWraper>
        <div className='w-4/5 m-auto'>
          <div className='mb-5 font-bold text-xl'>กิจกรรม</div>
          <div className='border border-solid border-secondary rounded-lg pb-1'>
            <div className='bg-secondary-50 p-4 text-secondary-500'>
              <Row gutter={16} align='middle' className='font-semibold'>
                <Col span={12}>Activities</Col>
                <Col span={4}>Date & time</Col>
                <Col span={8}>User</Col>
              </Row>
            </div>
            <div className='bg-white p-4 text-secondary-500'>
              {!!activities?.length &&
                activities.map((item) => {
                  return (
                    <Row gutter={16} align='middle' key={item._id}>
                      <Col span={12}>
                        <div>{appConfig.orderStatusEnum[item?.status as string]}</div>
                        {!!item.remark && <div className='text-[#E1221C]'>{item.remark}</div>}
                      </Col>
                      <Col span={4}>
                        <div className=''>{dayjs(item?.createdAt).format('DD-MM-YYYY')}</div>
                        <div className=''>{dayjs(item?.createdAt).format('HH:mm')}</div>
                      </Col>
                      <Col span={8}>
                        <div className='font-semibold'>{item?.createdBy?.name}</div>
                        <div className='uppercase tracking-wide'>{item?.createdBy?.employeeId}</div>
                      </Col>
                    </Row>
                  )
                })}
            </div>
          </div>
        </div>
      </ContentWraper>

      {!!isOpenExportQuotation && (
        <ModalExportQuotation
          loading={isLoadingExportQuotation}
          isOpen={isOpenExportQuotation}
          onOk={downloadQuotation}
          onCancel={() => setIsOpenExportQuotation(false)}
        />
      )}

      {!!isOpenPaymentApproval && (
        <ModalPaymentApproval
          activities={activities}
          isOpen={isOpenPaymentApproval}
          onCancel={() => setIsOpenPaymentApproval(false)}
          onHandleOrderApproveModal={setIsOpenPaymentApproveModal}
          onHandleOrderRejectModal={setIsOpenPaymentRejectModal}
        />
      )}

      {!!isOpenPaymentApproveModal && (
        <ModalApprove
          loading={loading}
          isOpen={isOpenPaymentApproveModal}
          onUpdateOrderStatus={onUpdateOrderStatus}
          onCancel={() => {
            setIsOpenPaymentApproveModal(false)
            setIsOpenPaymentApproval(true)
          }}
          statusToChange={getConfirmOrApprovePaymentStatus()}
          popupTitle='ยืนยันการอนุมัติเรียบร้อย'
          popupMessage='กรุณาส่งรายการไปยังขั้นตอนถัดไป'
          modalMessage={`เมื่อคุณยืนยันการอนุมัติการชำระเงินของออเดอร์ ‘${order?.orderNumber}’ นี้ จะถูกส่งต่อไปยังขั้นตอนถัดไป`}
        />
      )}

      {!!isOpenPaymentRejectModal && (
        <ModalReject
          loading={loading}
          isOpen={isOpenPaymentRejectModal}
          onUpdateOrderStatus={onUpdateOrderStatus}
          onCancel={() => {
            setIsOpenPaymentRejectModal(false)
            setIsOpenPaymentApproval(true)
          }}
          statusToChange={OrderStatusTypeEnum.PAYMENT_REJECTED}
          popupTitle='ปฏิเสธการชำระเงินเรียบร้อย'
          popupMessage='ระบบได้ทำการปฏิเสธการชำระเงินและส่งเรื่องไปยังผู้เกี่ยวข้องแล้ว'
          modalMessage={`เมื่อคุณยืนยันการปฏิเสธการชำระเงินของออเดอร์ ‘${order?.orderNumber}’ นี้ จะถูกตีกลับไปยังผู้เกี่ยวข้องในขั้นตอนก่อนหน้า กรุณาแจ้งเหตุผล หรือระบุข้อความเพิ่มเติมเพื่อชี้แจงให้ผู้เกี่ยวข้องทราบถึงเหตุผลในการปฏิเสธ`}
          modalSelectOption={['แก้ราคาหน้าบัตรและจำนวน', 'จำนวนเงินขาด', 'อื่นๆ']}
        />
      )}

      {!!isOpenUploadPayment && (
        <ModalUploadPayment
          isOpen={isOpenUploadPayment}
          onCancel={() => setIsOpenUploadPayment(false)}
          onOk={async () => {
            try {
              await onUpdateOrderStatus(OrderStatusTypeEnum.PAYMENT_SUBMITTED)
              setIsOpenUploadPayment(false)
              setIsOpenUploadPaymentSuccess(true)
            } catch (error) {
              setIsOpenUploadPayment(false)
              setIsOpenUploadPaymentFailed(true)
            }
          }}
        />
      )}

      {!!isOpenUploadPaymentSuccess && (
        <Popup
          title={
            order?.status !== OrderStatusTypeEnum.AWAITING_PAYMENT
              ? 'บันทึกข้อมูลการแจ้งชำระเงิน'
              : 'แจ้งชำระเงินแล้ว'
          }
          message={
            order?.status !== OrderStatusTypeEnum.AWAITING_PAYMENT ? (
              <div className='text-xl text-black font-bold'>เรียบร้อยแล้ว</div>
            ) : (
              ''
            )
          }
          isOpen={isOpenUploadPaymentSuccess}
          onOk={() => {
            window.location.reload()
          }}
          status='success'
          width={400}
        />
      )}

      {!!isOpenUploadPaymentFailed && (
        <Popup
          title='ระบบมีปัญหา'
          message={<div className='text-xl font-bold'>กรุณาทำรายการใหม่อีกครั้ง</div>}
          isOpen={isOpenUploadPaymentFailed}
          onOk={() => setIsOpenUploadPaymentFailed(false)}
          status='error'
          width={400}
        />
      )}

      {!!isOpenUploadInvoice && (
        <ModalUploadInvoice
          isOpen={isOpenUploadInvoice}
          onCancel={() => setIsOpenUploadInvoice(false)}
          onOk={async () => {
            try {
              await onUpdateOrderStatus(OrderStatusTypeEnum.INVOICE_SUBMITTED)
              setIsOpenUploadInvoice(false)
              setIsOpenUploadInvoiceSuccess(true)
            } catch (error) {
              setIsOpenUploadInvoice(false)
              setIsOpenUploadInvoiceFailed(true)
            }
          }}
        />
      )}

      {!!isOpenUploadInvoiceSuccess && (
        <Popup
          title={
            order?.status !== OrderStatusTypeEnum.ORDER_APPROVED
              ? 'แก้ไขใบแจ้งหนี้แล้ว'
              : 'อัปโหลดใบแจ้งหนี้แล้ว'
          }
          message=''
          isOpen={isOpenUploadInvoiceSuccess}
          onOk={() => {
            window.location.reload()
          }}
          status='success'
          width={400}
        />
      )}

      {!!isOpenUploadInvoiceFailed && (
        <Popup
          title='ระบบมีปัญหา'
          message={<div className='text-xl font-bold'>กรุณาทำรายการใหม่อีกครั้ง</div>}
          isOpen={isOpenUploadInvoiceFailed}
          onOk={() => setIsOpenUploadInvoiceFailed(false)}
          status='error'
          width={400}
        />
      )}

      {!!isOpenInvoiceApproval && (
        <ModalInvoiceApproval
          isOpen={isOpenInvoiceApproval}
          onCancel={() => setIsOpenInvoiceApproval(false)}
          onHandleInvoiceApproveModal={setIsOpenInvoiceApproveModal}
          onHandleInvoiceRejectModal={setIsOpenInvoiceRejectModal}
        />
      )}

      {!!isOpenInvoiceApproveModal && (
        <ModalApprove
          loading={loading}
          isOpen={isOpenInvoiceApproveModal}
          onUpdateOrderStatus={onUpdateOrderStatus}
          onCancel={() => {
            setIsOpenInvoiceApproveModal(false)
            setIsOpenInvoiceApproval(true)
          }}
          statusToChange={OrderStatusTypeEnum.INVOICE_APPROVED}
          popupTitle='ยืนยันการอนุมัติเรียบร้อย'
          popupMessage='กรุณาส่งรายการไปยังขั้นตอนถัดไป'
          modalMessage={`เมื่อคุณยืนยันใบแจ้งหนี้ของออเดอร์ ‘${order?.orderNumber}’ นี้ จะถูกส่งต่อไปยังขั้นตอนถัดไป`}
        />
      )}

      {!!isOpenInvoiceRejectModal && (
        <ModalReject
          loading={loading}
          isOpen={isOpenInvoiceRejectModal}
          onUpdateOrderStatus={onUpdateOrderStatus}
          onCancel={() => {
            setIsOpenInvoiceRejectModal(false)
            setIsOpenInvoiceApproval(true)
          }}
          statusToChange={OrderStatusTypeEnum.INVOICE_REJECTED}
          popupTitle='ปฏิเสธใบแจ้งหนี้เรียบร้อย'
          popupMessage='ระบบได้ทำการปฏิเสธการใบแจ้งหนี้และส่งเรื่องไปยังผู้เกี่ยวข้องแล้ว'
          modalMessage={`เมื่อคุณยืนยันการปฏิเสธใบแจ้งหนี้ ‘${order?.orderNumber}’ นี้ จะถูกตีกลับไปยังผู้เกี่ยวข้องในขั้นตอนก่อนหน้า กรุณาแจ้งเหตุผล หรือระบุข้อความเพิ่มเติมเพื่อชี้แจงให้ผู้เกี่ยวข้องทราบถึงเหตุผลในการปฏิเสธ`}
          modalSelectOption={['ข้อมูลไม่ถูกต้อง', 'อื่นๆ']}
        />
      )}

      {!!isOpenUpdateCardStatus && (
        <ModalUpdateCardStatus
          isOpen={isOpenUpdateCardStatus}
          onCancel={() => setIsOpenUpdateCardStatus(false)}
          onOk={async (status: OrderStatusTypeEnum) => {
            if (await onUpdateOrderStatus(status)) {
              setIsOpenUpdateCardStatus(false)
              setIsOpenUpdateCardStatusSuccess(true)
            }
          }}
        />
      )}

      {!!isOpenUpdateCardStatusSuccess && (
        <Popup
          title='บันทึกการเปลี่ยนแปลงแล้ว'
          message={<div className='text-sm my-2'>คุณได้อัปเดตสถานะสินค้าเรียบร้อย</div>}
          isOpen={isOpenUpdateCardStatusSuccess}
          onOk={() => {
            window.location.reload()
          }}
          status='success'
          width={400}
        />
      )}

      {!!isOpenUploadReceipt && (
        <ModalUploadReceipt
          isOpen={isOpenUploadReceipt}
          onCancel={() => setIsOpenUploadReceipt(false)}
          onOk={async () => {
            try {
              await onUpdateOrderStatus(OrderStatusTypeEnum.RECEIPT_UPLOADED)
              setIsOpenUploadReceipt(false)
              setIsOpenUploadReceiptSuccess(true)
            } catch (error) {
              setIsOpenUploadReceipt(false)
              setIsOpenUploadReceiptFailed(true)
            }
          }}
        />
      )}

      {!!isOpenUploadReceiptSuccess && (
        <Popup
          title={
            order?.status !== OrderStatusTypeEnum.INVOICE_APPROVED
              ? 'แก้ไขใบเสร็จรับเงินแล้ว'
              : 'อัปโหลดใบเสร็จรับเงินแล้ว'
          }
          message=''
          isOpen={isOpenUploadReceiptSuccess}
          onOk={() => {
            window.location.reload()
          }}
          status='success'
          width={400}
        />
      )}

      {!!isOpenUploadReceiptFailed && (
        <Popup
          title='ระบบมีปัญหา'
          message={<div className='text-xl font-bold'>กรุณาทำรายการใหม่อีกครั้ง</div>}
          isOpen={isOpenUploadReceiptFailed}
          onOk={() => setIsOpenUploadReceiptFailed(false)}
          status='error'
          width={400}
        />
      )}
    </div>
  )
}

export default OrderActivities
