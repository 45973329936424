import { useState } from 'react'
import { Button, Modal, Radio, RadioChangeEvent, Space } from 'antd'

interface IProps {
  isOpen: boolean
  loading: boolean
  onOk: (value: any) => void
  onCancel: () => void
}

const ModalExportQuotation = ({ isOpen, loading, onOk, onCancel }: IProps) => {
  const [exportLanguage, setExportLanguage] = useState<string>()

  const onChange = (e: RadioChangeEvent) => {
    setExportLanguage(e.target.value)
  }

  return (
    <Modal
      title='เลือกภาษาสำหรับออกใบเสนอราคา'
      open={isOpen}
      onCancel={onCancel}
      footer={null}
      centered
      bodyStyle={{ padding: '15px' }}
    >
      <div>
        <div className='text-secondary-500'>กรุณาเลือกภาษาที่ใช้ในการออกใบเสนอราคา</div>
        <div className='my-2 font-bold'>เลือกประเภท</div>
        <div className='my-2'>
          <Radio.Group onChange={onChange} value={exportLanguage} className='custom-radio-square'>
            <Space direction='vertical'>
              <Radio value='th'>ภาษาไทย</Radio>
              <Radio value='en'>ภาษาอังกฤษ</Radio>
            </Space>
          </Radio.Group>
        </div>
        <div className='mt-4'>
          <Button
            block
            type='primary'
            disabled={!exportLanguage}
            onClick={() => onOk(exportLanguage)}
            loading={loading}
          >
            ดาวน์โหลดไฟล์
          </Button>
        </div>
      </div>
    </Modal>
  )
}

export default ModalExportQuotation
