import { useEffect, useState } from 'react'
import { useGlobalStore } from 'stores/global'
import { Button, Layout } from 'antd'
import { DatePicker } from 'components/common/picker'
import dayjs, { Dayjs } from 'dayjs'
import SelectInput from 'components/common/select/Select'
import { CalendarOutlined } from '@ant-design/icons'
import './Report.scss'
import { generateFactoryReport } from 'services/report.service'
import Popup from 'components/common/popup/Popup'
const Content = Layout.Content
const ReportPage = () => {
  const { setPageHeader } = useGlobalStore()

  const [dates, setDates] = useState<Dayjs | null>()
  const [isFailed, setIsFailed] = useState<boolean>(false)

  useEffect(() => {
    setPageHeader({
      title: 'Reports',
      subTitle: '',
      onBack: '/reports',
      backIcon: false,
      extra: [],
    })
  }, [])

  const disabledDate = (current: Dayjs) => {
    return current.isAfter(dayjs().subtract(1, 'day'))
  }

  const disableDownloadButton = () => {
    return !dates
  }

  const onDownload = async () => {
    const query = {
      startDate: encodeURI(dayjs(dates).startOf('day').toISOString()),
      endDate: encodeURI(dayjs(dates).endOf('day').toISOString()),
    }
    try {
      const response = await generateFactoryReport(query)
      const url = URL.createObjectURL(new Blob([response.data]))
      const filename = response.headers['content-disposition'].split('filename=')[1]
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', `${decodeURI(filename)}`)
      document.body.appendChild(link)
      link.click()
    } catch (err) {
      setIsFailed(true)
    }
  }

  const handlePopupOk = () => {
    setIsFailed(false)
  }

  return (
    <>
      <Content>
        <div className='report-page flex gap-2'>
          <DatePicker
            disabledDate={disabledDate}
            size='large'
            format={['DD/MM/YYYY']}
            className='date-picker-report'
            suffixIcon={<CalendarOutlined />}
            onChange={(value) => {
              setDates(value)
            }}
          />

          <SelectInput
            options={[
              {
                value: 'REPORT',
                label: 'เอกสารเรียกบัตรโรงงาน',
              },
            ]}
            placeholder='Report type'
            value='REPORT'
          />
          <Button
            type='primary'
            shape='default'
            size='large'
            htmlType='submit'
            className='btn-report'
            disabled={disableDownloadButton()}
            onClick={onDownload}
          >
            Download
          </Button>
          <Popup
            title='ระบบมีปัญหา'
            message={<div className='text-xl font-bold'>กรุณาทำรายการใหม่อีกครั้ง</div>}
            isOpen={isFailed}
            onOk={handlePopupOk}
            status='error'
            width={400}
          />
        </div>
      </Content>
    </>
  )
}
export default ReportPage
