import { useNavigate } from 'react-router-dom'

const Forbidden = () => {
  const navigate = useNavigate()
  return (
    <div className='flex items-center justify-center w-screen h-screen bg-gradient-to-r from-teal-300 to-teal-100'>
      <div className='px-40 py-20 bg-white rounded-md shadow-xl'>
        <div className='flex flex-col items-center'>
          <h1 className='font-bold text-teal-300 text-9xl'>403</h1>

          <h6 className='mb-2 text-2xl font-bold text-center text-gray-800 md:text-3xl'>
            <span className='text-red-500'>Oops!</span> Access Denied
          </h6>

          <p className='mb-8 text-center text-gray-500 md:text-lg'>
            You do not have permission to access this page or behaviors
          </p>

          <div
            onClick={() => navigate('/signIn')}
            className='px-6 py-2 text-sm font-semibold text-teal-800 bg-blue-100 cursor-pointer'
          >
            Go home
          </div>
        </div>
      </div>
    </div>
  )
}

export default Forbidden
