import fetch from '../utilities/fetchAxios'

export const getOrders = async (query) => {
  const method = 'GET'
  const path = '/order/v1/orders'
  return fetch(method, path, query)
}

export const orderSummary = async (body) => {
  const method = 'POST'
  const path = '/order/v1/summary'
  return fetch(method, path, body)
}

export const createOrder = async (body) => {
  const method = 'POST'
  const path = '/order/v1'
  return fetch(method, path, body)
}

export const orderCalculateFreebie = async (body) => {
  const method = 'POST'
  const path = '/order/v1/calculate-freebie'
  return fetch(method, path, body)
}

export const getOrderById = async (id) => {
  const method = 'GET'
  const path = `/order/v1/${id}`
  return fetch(method, path)
}

export const updateOrder = async (body) => {
  const method = 'PUT'
  const path = '/order/v1/update'
  return fetch(method, path, body)
}

export const exportQuotation = async (query, headers) => {
  const method = 'GET'
  const path = '/order/v1/export/quotation'
  return fetch(method, path, query, headers, { responseType: 'blob' }, false)
}

export const updateOrderStatus = async (body) => {
  const method = 'POST'
  const path = '/order/v1/update/status'
  return fetch(method, path, body)
}

export const getOrderSignUrl = async (id) => {
  const method = 'GET'
  const path = `/order/v1/signed-url?orderId=${id}`
  return fetch(method, path)
}

export const uploadOrderFile = async (body) => {
  const method = 'POST'
  const path = '/order/v1/upload'
  return fetch(method, path, body)
}
