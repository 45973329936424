import { create } from 'zustand'
import { Corporate } from './types/corporate.types'

type State = {
  corporate: Corporate | null
  setCorporate: (corporate: Corporate | null) => void
}

export const useCorporateStore = create<State>((set) => ({
  corporate: null,
  setCorporate: (corporate) =>
    set(() => ({
      corporate: corporate,
    })),
}))
