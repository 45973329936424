import { useEffect, useState } from 'react'
import { Button, Modal } from 'antd'
import { OrderStatusTypeEnum } from 'enums/order'
import Popup from 'components/common/popup/Popup'

interface IProps {
  loading: boolean
  isOpen: boolean
  onUpdateOrderStatus: (status: OrderStatusTypeEnum, remark?: string) => any
  onCancel: () => void
  statusToChange: OrderStatusTypeEnum
  popupTitle: string
  popupMessage: string
  modalMessage: string
  modalTitle?: string
  modalApproveButton?: string
}

const ModalApprove = ({
  loading,
  isOpen,
  onUpdateOrderStatus,
  onCancel,
  statusToChange,
  popupTitle,
  popupMessage,
  modalMessage,
  modalTitle = 'ยืนยันการอนุมัติ',
  modalApproveButton = 'ยืนยันการอนุมัติ',
}: IProps) => {
  const [isOpenModal, setIsOpenModal] = useState<boolean>(false)
  const [isSuccess, setIsSuccess] = useState<boolean>(false)
  const [isFailed, setIsFailed] = useState<boolean>(false)

  useEffect(() => {
    setIsOpenModal(isOpen)
  }, [isOpen])

  const onGoback = () => {
    onCancel()
  }

  const onPaymentApprove = async () => {
    try {
      await onUpdateOrderStatus(statusToChange)
      setIsOpenModal(false)
      setIsSuccess(true)
    } catch (error) {
      setIsOpenModal(false)
      setIsFailed(true)
    }
  }

  const handleOk = () => {
    setIsSuccess(false)
    onCancel()
    window.location.reload()
  }

  const handleFailed = () => {
    setIsFailed(false)
  }

  return (
    <div>
      <Modal
        title={modalTitle}
        className='payment-approval-modal'
        width={700}
        open={isOpenModal}
        onCancel={onCancel}
        centered
        footer={[
          <Button key='back' type='default' className='goback-modal-btn' onClick={onGoback}>
            กลับ
          </Button>,
          <Button
            key='submit'
            type='primary'
            className='approve-modal-btn'
            loading={loading}
            onClick={onPaymentApprove}
          >
            {modalApproveButton}
          </Button>,
        ]}
      >
        <div className='text-base'>{modalMessage}</div>
      </Modal>

      <Popup
        title={popupTitle}
        message={popupMessage}
        isOpen={isSuccess}
        okText='ตกลง'
        onOk={handleOk}
        status='success'
        width={400}
      />

      <Popup
        title='ระบบมีปัญหา'
        message={<div className='text-xl font-bold'>กรุณาทำรายการใหม่อีกครั้ง</div>}
        isOpen={isFailed}
        onOk={handleFailed}
        status='error'
        width={400}
      />
    </div>
  )
}

export default ModalApprove
