import React, { useEffect, useState } from 'react'
import { Button, Col, Modal, Row } from 'antd'
import { useOrderStore } from 'stores/order'
import { OrderStatusTypeEnum } from 'enums/order'
import dayjs from 'dayjs'
import { formatNumber, getFileExtension } from 'utilities/common'
import { summary } from 'utilities/calculate'
import ContentPanel from 'components/common/panel/PanalContent'

interface IProps {
  isOpen: boolean
  onCancel: () => void
  activities: any
  onHandleOrderApproveModal: (value: any) => void
  onHandleOrderRejectModal: (value: any) => void
}

const ModalPaymentApproval = ({
  isOpen,
  onCancel,
  activities,
  onHandleOrderApproveModal,
  onHandleOrderRejectModal,
}: IProps) => {
  const { order } = useOrderStore()
  const [paymentSubmitDetail, setPaymentSubmitDetail] = useState({
    actionDate: '',
    actionTime: '',
    remark: '-',
  })

  useEffect(() => {
    getPaymentSubmitDetail()
  }, [])

  const getPaymentSubmitDetail = () => {
    const sortedDesc = activities.sort((objA: any, objB: any) => {
      return dayjs(objB.createdAt).isAfter(dayjs(objA.createdAt)) ? 1 : -1
    })
    const detail = sortedDesc.find((obj: any) => {
      return obj.status === OrderStatusTypeEnum.PAYMENT_SUBMITTED
    })
    setPaymentSubmitDetail({
      actionDate: dayjs(detail?.createdAt).format('DD-MM-YYYY'),
      actionTime: dayjs(detail?.createdAt).format('HH:mm'),
      remark: detail?.remark,
    })
  }

  const onPaymentApproveModal = () => {
    onCancel()
    onHandleOrderApproveModal(true)
  }

  const onPaymentRejectModal = () => {
    onCancel()
    onHandleOrderRejectModal(true)
  }

  return (
    <Modal
      title='ตรวจสอบการชำระเงิน'
      className='payment-approval-modal'
      width={700}
      open={isOpen}
      onCancel={onCancel}
      centered
      footer={[
        <Button key='reject' className='reject-modal-btn' onClick={onPaymentRejectModal}>
          ปฏิเสธการชำระเงิน
        </Button>,
        <Button
          key='submit'
          type='primary'
          className='approve-modal-btn'
          onClick={onPaymentApproveModal}
        >
          อนุมัติการชำระเงิน
        </Button>,
      ]}
    >
      <ContentPanel shadow={false} className='py-4 px-10 bg-secondary-50'>
        <Row gutter={16} className='text-secondary-500 text-base'>
          <Col className='py-2' span={16}>
            {order?.orderNumber}
          </Col>
          <Col className='py-2 text-right' span={8}>
            THB {formatNumber(summary(order?.purchaseTotal || 0, order?.customizeTotal || 0), 2)}
          </Col>
        </Row>

        <Row
          gutter={16}
          className='border-t border-solid border-secondary-300 text-base font-semibold mt-4'
        >
          <Col className='py-2' span={16}>
            ยอดที่ต้องได้รับ
          </Col>
          <Col className='py-2 text-right' span={8}>
            THB {formatNumber(summary(order?.purchaseTotal || 0, order?.customizeTotal || 0), 2)}
          </Col>
        </Row>
      </ContentPanel>
      <ContentPanel shadow={false} className='py-3 px-10 bg-white'>
        <Row gutter={16} className='text-secondary-800 text-base'>
          <Col className='py-2 text-lg'>ข้อมูลการแจ้งชำระเงิน</Col>
        </Row>
        <Row gutter={16}>
          <Col className='py-2' span={16}>
            วันที่แจ้งชำระ
          </Col>
          <Col className='py-2 text-right' span={8}>
            {paymentSubmitDetail.actionDate}
          </Col>
        </Row>

        <Row gutter={16}>
          <Col className='py-2' span={16}>
            เวลาที่แจ้งชำระ
          </Col>
          <Col className='py-2 text-right' span={8}>
            {paymentSubmitDetail.actionTime}
          </Col>
        </Row>

        <Row gutter={16}>
          <Col className='py-2' span={16}>
            ข้อความ
          </Col>
          <Col className='py-2 text-right' span={8}>
            {paymentSubmitDetail.remark || '-'}
          </Col>
        </Row>
      </ContentPanel>
      <ContentPanel shadow={false} className='py-3 px-10 bg-white'>
        <Row gutter={16} className='text-secondary-800 text-base'>
          <Col className='py-2 text-lg'>หลักฐานการชำระเงิน</Col>
        </Row>
        {!!order?.payment?.files?.length &&
          order?.payment?.files.map((obj: any, index: number) => {
            return (
              <Row gutter={16} key={index}>
                <Col className='py-1' span={16}>
                  <div className='flex gap-2 items-center'>
                    <img
                      src={`/assets/images/icons/icon-${getFileExtension(
                        obj.originalFileName,
                      )}.svg`}
                      loading='lazy'
                      alt='jpg'
                    />
                    <a
                      href={obj.url}
                      target='_blank'
                      rel='noopener noreferrer'
                      className='text-primary underline'
                    >
                      {obj.originalFileName}
                    </a>
                  </div>
                </Col>
              </Row>
            )
          })}
      </ContentPanel>
    </Modal>
  )
}

export default ModalPaymentApproval
