import { useEffect, useState } from 'react'
import { Button, Modal, Select } from 'antd'
import { OrderStatusTypeEnum } from 'enums/order'
import Popup from 'components/common/popup/Popup'
import TextArea from 'antd/lib/input/TextArea'

interface IProps {
  loading: boolean
  isOpen: boolean
  onUpdateOrderStatus: (status: OrderStatusTypeEnum, remark?: string) => any
  onCancel: () => void
  statusToChange: OrderStatusTypeEnum
  popupTitle: string
  popupMessage: string
  modalMessage: string
  modalSelectOption: string[]
}
const { Option } = Select

const ModalReject = ({
  loading,
  isOpen,
  onUpdateOrderStatus,
  onCancel,
  statusToChange,
  popupTitle,
  popupMessage,
  modalMessage,
  modalSelectOption,
}: IProps) => {
  const [isOpenModal, setIsOpenModal] = useState<boolean>(false)
  const [isSuccess, setIsSuccess] = useState<boolean>(false)
  const [isFailed, setIsFailed] = useState<boolean>(false)
  const [rejectReason, setRejectReason] = useState<string>('')
  const [rejectRemark, setRejectRemark] = useState<string>('')

  useEffect(() => {
    setIsOpenModal(isOpen)
  }, [isOpen])

  const onGoback = () => {
    onCancel()
  }

  const onPaymentReject = async () => {
    const remark = rejectRemark ? `${rejectReason}-${rejectRemark}` : rejectReason
    try {
      await onUpdateOrderStatus(statusToChange, remark)
      setIsOpenModal(false)
      setIsSuccess(true)
    } catch (error) {
      setIsOpenModal(false)
      setIsFailed(true)
    }
  }

  const handleOk = () => {
    setIsSuccess(false)
    onCancel()
    window.location.reload()
  }

  const handleFailed = () => {
    setIsFailed(false)
  }

  const handleChangeRejectReason = (reason: string) => {
    setRejectReason(reason)
  }

  const handleChangeRejectRemark = (event: any) => {
    setRejectRemark(event?.target.value)
  }
  return (
    <div>
      <Modal
        title='ยืนยันการปฏิเสธ'
        className='payment-approval-modal'
        width={700}
        open={isOpenModal}
        onCancel={onCancel}
        centered
        footer={[
          <Button key='back' type='default' className='goback-modal-btn' onClick={onGoback}>
            กลับ
          </Button>,
          <Button
            key='reject'
            className='reject-modal-btn'
            loading={loading}
            onClick={onPaymentReject}
          >
            ยืนยันการปฏิเสธ
          </Button>,
        ]}
      >
        <div className='text-base'>{modalMessage}</div>
        <br />
        <div>
          <Select
            placeholder='เหตุผลที่ปฏิเสธ'
            size='large'
            style={{ width: 650 }}
            allowClear
            onChange={handleChangeRejectReason}
          >
            {modalSelectOption?.map((option: any, index: number) => (
              <Option value={option} key={index}>
                {option}
              </Option>
            ))}
          </Select>
        </div>
        <br />
        <div>
          <TextArea
            placeholder='ข้อความชี้แจง เช่น จำนวนเงินที่ขาด (ถ้ามี)'
            autoSize={{ minRows: 2, maxRows: 6 }}
            onChange={handleChangeRejectRemark}
          />
        </div>
      </Modal>
      <Popup
        title={popupTitle}
        message={popupMessage}
        isOpen={isSuccess}
        okText='ตกลง'
        onOk={handleOk}
        status='success'
        width={400}
      />

      <Popup
        title='ระบบมีปัญหา'
        message={<div className='text-xl font-bold'>กรุณาทำรายการใหม่อีกครั้ง</div>}
        isOpen={isFailed}
        onOk={handleFailed}
        status='error'
        width={400}
      />
    </div>
  )
}

export default ModalReject
