import { create } from 'zustand'
import jwtDecode, { JwtPayload } from 'jwt-decode'
import { Auth } from './types/authorized.types'
import { AUTHEN } from 'constants/storageKeys'
interface AccessTokenPayload extends JwtPayload {
  name: string
  username: string
  email: string
  userType: string
  role: string
  employeeId: string
}

type State = {
  auth: Auth | undefined
}

export const useAuth = create<State>(() => {
  let $authData: any = {
    user: {
      name: undefined,
      username: undefined,
      email: undefined,
      userType: undefined,
      role: undefined,
      employeeId: undefined,
    },
    isLoggedIn: false,
  }
  const accessToken = sessionStorage.getItem(AUTHEN.ACCESS_TOKEN)
  if (accessToken) {
    const decoded = jwtDecode<AccessTokenPayload>(accessToken)
    $authData = {
      user: {
        name: decoded.name,
        username: decoded.username,
        email: decoded.email,
        userType: decoded.userType,
        role: decoded.role,
        employeeId: decoded.employeeId,
      },
      isLoggedIn: true,
    }
  }

  return {
    auth: $authData,
  }
})
