import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Button, Layout } from 'antd'
import { PlusOutlined } from '@ant-design/icons'
import { useGlobalStore, useAppConfigStore } from 'stores/global'
import { getOrders } from 'services/order.service'
import { Order, SearchOrderList } from 'stores/types/order.types'
import { Paginate } from 'stores/types/common.type'
import { convertOffsetToPage } from 'utilities/common'
import { useOrderStore } from 'stores/order'
import { useCorporateStore } from 'stores/corporate'
import { useProductCatalogStore } from 'stores/productCatalog'
import { ROLE_ACCESS } from 'constants/accessRole'

import OrderTable from './components/Table'
import OrderSearch from './components/Search'
import PermissionAccess from 'components/PermissionAccess'
import { OrderStatusTypeEnum } from 'enums/order'

const Content = Layout.Content

const OrderPage = () => {
  const navigate = useNavigate()
  const { appConfig } = useAppConfigStore()
  const { setPageHeader } = useGlobalStore()
  const { setOrder, setCurrentStep } = useOrderStore()
  const { setCorporate } = useCorporateStore()
  const { setCatalog, setProductCatalogs } = useProductCatalogStore()

  const [orders, setOrders] = useState<Order[]>([])
  const [loading, setLoading] = useState<boolean>(false)
  const [pagination, setPagination] = useState<Paginate>({
    offset: 0,
    limit: 10,
    total: 0,
    page: 0,
  })

  const [search, setSearch] = useState<SearchOrderList>({
    keyword: undefined,
    status: undefined,
    productCatalogType: undefined,
    startDate: undefined,
    endDate: undefined,
  })

  useEffect(() => {
    setPageHeader({
      title: 'Orders',
      subTitle: '',
      onBack: '/orders',
      backIcon: false,
      extra: [
        <PermissionAccess roles={ROLE_ACCESS.ORDER_LIST.CREATE} key={0}>
          <Button
            type='primary'
            shape='default'
            size='large'
            className=''
            icon={<PlusOutlined />}
            onClick={() => clickCreateOrder()}
          >
            Create Quotation
          </Button>
        </PermissionAccess>,
      ],
    })
    setOrder(null)
    setCurrentStep(0)
    setCorporate(null)
    setCatalog(null)
    setProductCatalogs([])
  }, [])

  useEffect(() => {
    if (Object.keys(appConfig)?.length) {
      const searchValue = {
        status: appConfig?.orderStatus?.length ? appConfig?.orderStatus[0].key : undefined,
      }
      setSearch(searchValue)
      findOrders(searchValue)
    }
  }, [appConfig])

  const clickCreateOrder = () => {
    setOrder(null)
    setCurrentStep(0)
    setCorporate(null)
    setCatalog(null)
    setProductCatalogs([])
    navigate('/order/create')
  }

  const findOrders = async (param = {}) => {
    setLoading(true)
    const query = {
      ...pagination,
      ...search,
      ...param,
    }
    const payload = await getOrders(query)
    if (payload?.status?.code === 200) {
      setOrders(payload.data)
      setPagination({
        limit: query.limit,
        offset: payload.meta.offset,
        total: payload.meta?.total,
        page: convertOffsetToPage(payload.meta?.offset, payload.meta?.limit),
      })
    }
    setLoading(false)
  }

  const changePage = (page: number, limit: number) => {
    const offset = limit * (page - 1)
    const paginate = {
      ...pagination,
      offset,
      limit,
    }
    setPagination(paginate)
    findOrders(paginate)
  }

  const onSearch = (searchValues: SearchOrderList) => {
    const paginate = {
      ...pagination,
      offset: 0,
    }
    setPagination(paginate)
    setSearch(searchValues)
    findOrders({
      ...searchValues,
      ...paginate,
      offset: 0,
    })
  }

  return (
    <>
      <Content>
        {!!Object.keys(appConfig)?.length && (
          <OrderSearch
            onSubmit={onSearch}
            loading={loading}
            initialValues={{
              status: appConfig?.orderStatus?.length ? appConfig?.orderStatus[0].key : undefined,
            }}
          />
        )}
      </Content>

      <Content>
        <OrderTable
          loading={loading}
          dataSource={orders}
          pagination={pagination}
          changePage={changePage}
          status={search.status as OrderStatusTypeEnum}
          onRefresh={findOrders}
        />
      </Content>
    </>
  )
}

export default OrderPage
