import { useEffect, useState } from 'react'
import { Alert, Button, Col, Modal, Row, Spin } from 'antd'
import { LoadingOutlined } from '@ant-design/icons'
import { useOrderStore } from 'stores/order'
import { useProductCatalogStore } from 'stores/productCatalog'
import { OrderItems } from 'stores/types/order.types'
import { formatNumber } from 'utilities/common'
import { calculateSum, calculateSumItems } from 'utilities/calculate'

import DropUpload from 'components/common/upload/DropUpload'
import { PaymentUploadTypeEnum } from 'enums/payment'
import { uploadPyment } from 'services/payment.service'
import { isAllowEditUploadPayment } from 'utilities/rules'

interface IProps {
  isOpen: boolean
  onOk: (value: any) => void
  onCancel: () => void
}

const ModalUploadPayment = ({ isOpen, onOk, onCancel }: IProps) => {
  const { order, setOrder } = useOrderStore()
  const { catalog } = useProductCatalogStore()
  const [filesUpload, setFilesUpload] = useState<any>([])
  const [deleteFile, setDeleteFile] = useState<string[]>([])
  const [orderItems, setOrderItems] = useState<OrderItems[]>([])

  const [loading, setLoading] = useState<boolean>(false)
  const [isErrorUpload, setIsErrorUpload] = useState<boolean>(false)

  useEffect(() => {
    if (order?.orderItems?.length) {
      const items = calculateSumItems(order?.orderItems, 'amount', 'quantity')
      setOrderItems(items)
    }
    if (order?.payment?.files?.length) {
      setFilesUpload(order?.payment?.files)
    }
  }, [])

  const onUpload = (fileItem) => {
    setFilesUpload((prevFiles) => {
      return [...prevFiles, fileItem]
    })
  }

  const onDelete = (fileItem) => {
    if (fileItem?.imageUrl) {
      const files = filesUpload.filter((f) => f?.file?.uid !== fileItem?.file?.uid)
      setFilesUpload(files)
    }
    if (fileItem?.url) {
      const files = filesUpload.filter((f) => f?.path !== fileItem?.path)
      setFilesUpload(files)
      setDeleteFile((prevFiles) => {
        return [...prevFiles, fileItem.path]
      })
    }
  }

  const handleOnOk = async () => {
    setIsErrorUpload(false)
    setLoading(true)
    try {
      const formData = new FormData()
      formData.append('type', PaymentUploadTypeEnum.PAYMENT_SLIP)
      formData.append('orderId', `${order?._id}`)
      if (order?.payment?.paymentId) {
        formData.append('paymentId', `${order?.payment?.paymentId}`)
      }

      for (const item of filesUpload) {
        if (item.file) {
          formData.append('file', item.file)
        }
      }
      // must have payment id
      if (order?.payment?.paymentId && deleteFile?.length) {
        formData.append('deleteFile', JSON.stringify(deleteFile))
      }

      const payload = await uploadPyment(formData)
      if (payload?.status?.code === 200) {
        setOrder({
          payment: {
            paymentId: payload?.data?.paymentId,
            files: payload?.data?.files,
          },
        })
        onOk('success')
      }
    } catch (error) {
      setIsErrorUpload(true)
      scrollToElement('alert-error')
    }
    setLoading(false)
  }

  const scrollToElement = (id) => {
    setTimeout(() => {
      const element = document.getElementById(id)
      if (element) {
        element.scrollIntoView({ block: 'start', behavior: 'smooth' })
      }
    }, 500)
  }

  const handleDisabled = () => {
    return (
      !filesUpload?.length ||
      (order?.payment?.files?.length === filesUpload?.length && !deleteFile?.length)
    )
  }

  return (
    <Modal
      title='แจ้งการชำระเงิน'
      open={isOpen}
      onCancel={onCancel}
      footer={null}
      centered
      width={678}
      bodyStyle={{ padding: '15px' }}
    >
      <div className='max-h-[500px] overflow-auto'>
        <div className='border border-solid border-secondary-100 rounded-lg p-4 mb-4'>
          <Row gutter={16} className='mb-2'>
            <Col span={24}>
              <div className='text-lg font-bold'>รายละเอียดคำสั่งซื้อ</div>
            </Col>
          </Row>
          <Row gutter={16} className='my-2'>
            <Col span={9}>Order No.</Col>
            <Col span={14} className='font-semibold'>
              {order?.orderNumber}
            </Col>
          </Row>
          <Row gutter={16} className='my-2'>
            <Col span={9}>Quotation No.</Col>
            <Col span={14} className='font-semibold'>
              {order?.orderNumber}
            </Col>
          </Row>
          <Row gutter={16} className='my-2'>
            <Col span={9}>Coporate No.</Col>
            <Col span={14} className='font-semibold'>
              {order?.corporate?.corporateNumber}
            </Col>
          </Row>
          <Row gutter={16} className='my-2'>
            <Col span={9}>Corporate Name</Col>
            <Col span={14} className='font-semibold'>
              {order?.corporate?.corporateName?.th}
            </Col>
          </Row>
        </div>

        <div className='border border-solid border-secondary-100 rounded-lg p-4 mb-4'>
          <Row gutter={16} className='mb-2'>
            <Col span={24}>
              <div className='text-lg font-bold'>ยอดเงินที่ต้องชำระ</div>
            </Col>
          </Row>
          {!!orderItems?.length &&
            orderItems?.map((item, index) => {
              return (
                <Row key={'order-item-' + index} gutter={16} className='my-2'>
                  <Col span={9}>
                    {catalog?.type?.fullName} ฿{formatNumber(item?.amount)}
                  </Col>
                  <Col span={9} className=''>
                    Qty: {formatNumber(item.quantity)}
                  </Col>
                  <Col span={6} className='text-right'>
                    THB: {formatNumber(item.total, 2)}
                  </Col>
                </Row>
              )
            })}
          <Row
            gutter={16}
            className='border-t border-solid border-secondary-100 font-semibold mt-2 py-2'
          >
            <Col span={9}>Subtotal</Col>
            <Col span={9} automate-test='total-quantity'>
              Qty: {formatNumber(calculateSum(orderItems, 'quantity'))}
            </Col>
            <Col span={6} automate-test='total-summary' className='text-right'>
              THB: {formatNumber(order?.purchaseTotal, 2)}
            </Col>
          </Row>
        </div>

        <div className='border border-solid border-secondary-100 rounded-lg p-4 mb-4'>
          {!!isErrorUpload && (
            <div id='alert-error'>
              <Alert
                type='error'
                showIcon
                description={
                  <div className='text-xs text-red-600 mt-1'>
                    เกิดข้อผิดพลาดขณะอัปโหลด กรุณากดปุ่ม “ยืนยันการแจ้งชำระเงิน” อีกครั้ง
                    เพื่อส่งข้อมูลการอัปโหลดไฟล์
                  </div>
                }
                className='my-2 border-none'
              />
            </div>
          )}

          <Row gutter={16} className='mb-2'>
            <Col span={24}>
              <div className='text-lg font-bold'>อัปโหลดหลักฐานการชำระเงิน</div>
            </Col>
          </Row>
          <div className='my-2'>
            <Spin spinning={loading} indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />}>
              <DropUpload
                files={filesUpload}
                onUpload={onUpload}
                onDelete={onDelete}
                maxCount={10}
                accept='.jpg,.jpeg,.png,.pdf'
              />
            </Spin>
          </div>
        </div>
      </div>
      <div>
        <div className='flex gap-2 mb-4'>
          <div className='w-1/2'>
            <Button type='default' size='large' block onClick={onCancel} disabled={loading}>
              กลับ
            </Button>
          </div>

          <div className='w-1/2'>
            <Button
              type='primary'
              size='large'
              block
              onClick={handleOnOk}
              disabled={handleDisabled()}
              loading={loading}
            >
              {isAllowEditUploadPayment(order?.status) ? 'บันทึก' : 'ยืนยันการแจ้งชำระเงิน'}
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  )
}

export default ModalUploadPayment
