import { Navigate, useLocation } from 'react-router-dom'
import { Layout, ConfigProvider } from 'antd'
// Components
import Content from './layouts/content'
import Footer from './layouts/footer'
import Navbar from './layouts/navbar'
import Sidebar from './layouts/sidebar'

import { useAuth } from '../stores/authorized'
import { ROLE_ACCESS } from 'constants/accessRole'

interface IProps {
  roles?: string[]
  component: React.ComponentProps<any>
}
const PrivateRoute = ({ roles, component: Component }: IProps) => {
  const location = useLocation()
  const { auth } = useAuth()

  const hasRole = () => {
    const allowedRoles = roles && roles?.length ? roles : ROLE_ACCESS.ALL
    return allowedRoles.includes(auth?.user?.role)
  }

  if (!auth?.isLoggedIn) {
    return <Navigate replace to='/signin' state={{ from: location.pathname }} />
  }

  if (!hasRole()) {
    return <Navigate replace to='/404' state={{ from: location.pathname }} />
  }

  return (
    <ConfigProvider>
      <Navbar />
      <Layout id='app-layout' className='min-h-screen'>
        <Sidebar />
        <Layout className='site-layout'>
          <Content>
            <Component />
          </Content>
          <Footer />
        </Layout>
      </Layout>
    </ConfigProvider>
  )
}

export default PrivateRoute
