import { Button, Form, Radio, Select, Space } from 'antd'
import type { FormInstance } from 'antd/es/form/Form'
import { OrderDeliveryTypeEnum, OrderStepTypeEnum, ProductCatalogTypeEnum } from 'enums/order'
import { useEffect, useState } from 'react'
import { useCorporateStore } from 'stores/corporate'
import { useOrderStore } from 'stores/order'
import { mergeStringArray } from 'utilities/common'

import ContentPanel from 'components/common/panel/PanalContent'
import FormDeliveryAddress from '../components/FormDeliveryAddress'

interface IProps {
  form: FormInstance
}

const StepSelectAddress = ({ form }: IProps) => {
  const { currentStep, setCurrentStep, order, setOrder } = useOrderStore()
  const { corporate } = useCorporateStore()
  const [billingAddress, setBillingAddress] = useState<string>()

  useEffect(() => {
    if (currentStep === OrderStepTypeEnum.STEP_ADDRESS) {
      if (corporate) {
        const billing = mergeStringArray([
          corporate?.streetAddress1,
          corporate?.subdistrict,
          corporate?.district,
          corporate?.province,
          corporate?.postalCode,
        ])
        setBillingAddress(billing)
      }

      if (
        order?.deliveryType === OrderDeliveryTypeEnum.DELIVERY &&
        order?.deliveryAddress?.postalCode
      ) {
        form.setFieldValue('selectDelivery', 'addnew')
      }

      if (order?.productCatalogType === ProductCatalogTypeEnum.E_COUPON) {
        form.setFieldValue('deliveryType', OrderDeliveryTypeEnum.DELIVERY)
        setOrder({
          ...order,
          information: {
            contactName: corporate?.corporateName?.th,
            email: corporate?.email,
            phoneNumber: corporate?.phoneNumber,
          },
          deliveryAddress: undefined,
        })
      }
    }
  }, [currentStep])

  const handleChangeDeliveryType = () => {
    form.setFieldValue('deliveryAddress', {
      streetAddress1: undefined,
      province: undefined,
      district: undefined,
      subdistrict: undefined,
      postalCode: undefined,
    })

    setOrder({
      ...order,
      information: {
        contactName: corporate?.corporateName?.th,
        email: corporate?.email,
        phoneNumber: corporate?.phoneNumber,
      },
      deliveryAddress: {
        streetAddress1: undefined,
        province: undefined,
        district: undefined,
        subdistrict: undefined,
        postalCode: undefined,
      },
    })
  }

  const handleChangePickup = (value) => {
    setOrder({
      ...order,
      information: {
        contactName: corporate?.corporateName?.th,
        email: corporate?.email,
        phoneNumber: corporate?.phoneNumber,
      },
      deliveryAddress: {
        streetAddress1: value,
        province: '-',
        district: '-',
        subdistrict: '-',
        postalCode: '-',
      },
    })
  }

  const handleClickNextStep = async () => {
    const formValue = await form.validateFields()
    if (formValue) {
      if (formValue.deliveryType === OrderDeliveryTypeEnum.DELIVERY) {
        delete formValue.selectDelivery
      }
      setOrder({
        ...formValue,
        deliveryAddress: order?.deliveryAddress,
      })

      if (order?._id) {
        form.submit()
      } else {
        setCurrentStep(OrderStepTypeEnum.STEP_SUMMARY)
      }
    }
  }

  const handleClickPreviousStep = () => {
    let step = OrderStepTypeEnum.STEP_ORDER
    if (
      order?.isAcceptFreebie &&
      order?.freebieRate > 0 &&
      order?.productCatalogType !== ProductCatalogTypeEnum.E_COUPON
    ) {
      step = OrderStepTypeEnum.STEP_FREEBIE
    }
    setCurrentStep(step)
  }

  const disabledNextStep = () => {
    return !form.getFieldValue('deliveryType')
  }

  const disableDeliveryType = (deliveryType) => {
    return order?.productCatalogType === ProductCatalogTypeEnum.E_COUPON &&
      deliveryType === OrderDeliveryTypeEnum.PICKUP
      ? true
      : false
  }

  return (
    <>
      <ContentPanel title='Select Shipment Type'>
        <Form.Item
          name='deliveryType'
          className='m-0'
          rules={[
            {
              required: currentStep === OrderStepTypeEnum.STEP_ADDRESS,
              message: 'please select delivery type',
            },
          ]}
        >
          <Radio.Group>
            <Space direction='vertical'>
              {Object.keys(OrderDeliveryTypeEnum).map((item, key) => {
                return (
                  <Radio
                    key={item}
                    value={item}
                    disabled={disableDeliveryType(item)}
                    onChange={handleChangeDeliveryType}
                    automate-test={`radio-delivery-type-${key}`}
                  >
                    <span className='capitalize'>{item.toLowerCase()}</span>
                  </Radio>
                )
              })}
            </Space>
          </Radio.Group>
        </Form.Item>
      </ContentPanel>

      <Form.Item noStyle shouldUpdate>
        {({ getFieldValue }) => (
          <>
            {getFieldValue('deliveryType') === OrderDeliveryTypeEnum.PICKUP && (
              <>
                <ContentPanel title='Select Pickup Place'>
                  <Form.Item
                    name={['deliveryAddress', 'streetAddress1']}
                    className='m-0'
                    rules={[{ required: true, message: 'please select delivery address' }]}
                  >
                    <Select
                      className='w-full'
                      size='large'
                      placeholder='Select pickup place'
                      options={[
                        { value: 'Factory', label: 'Factory' },
                        { value: 'Lotus’s Head Office', label: 'Lotus’s Head Office' },
                      ]}
                      onChange={handleChangePickup}
                      automate-test='select-delivery-address'
                    />
                  </Form.Item>
                </ContentPanel>
                <FormDeliveryAddress form={form} />
              </>
            )}
            {getFieldValue('deliveryType') === OrderDeliveryTypeEnum.DELIVERY && (
              <>
                <ContentPanel title='Select Delivery Information'>
                  <div className='rounded-lg border border-solid border-secondary-100 p-5 text-secondary-500'>
                    <Form.Item
                      name='selectDelivery'
                      className='m-0'
                      rules={[{ required: true, message: 'please select delivery information' }]}
                    >
                      <Radio.Group size='large'>
                        <Space direction='vertical'>
                          <Radio value='addnew' automate-test='redio-addnew-address'>
                            Add new delivery information
                          </Radio>
                        </Space>
                      </Radio.Group>
                    </Form.Item>
                  </div>
                </ContentPanel>
                {getFieldValue('selectDelivery') === 'addnew' && (
                  <FormDeliveryAddress form={form} />
                )}
              </>
            )}
          </>
        )}
      </Form.Item>

      <ContentPanel
        title='Billing Information'
        shadow={false}
        className='border border-solid border-secondary-100'
      >
        <div className='rounded-lg border border-solid border-secondary-100 p-5 text-secondary-500'>
          <div className='text-lg font-semibold'>{corporate?.corporateName?.th}</div>
          <div className='text-md'>{billingAddress}</div>
          <div className='text-md'>TAX ID: {corporate?.taxId}</div>
        </div>
      </ContentPanel>

      <Form.Item shouldUpdate>
        {() => {
          return (
            <div className='mt-10 flex gap-2 justify-center'>
              {order?._id ? (
                <Button
                  type='default'
                  size='large'
                  onClick={() => setCurrentStep(OrderStepTypeEnum.STEP_SUMMARY)}
                  automate-test='btn-cancel-step-address'
                >
                  Cancel
                </Button>
              ) : (
                <Button
                  type='default'
                  size='large'
                  onClick={handleClickPreviousStep}
                  automate-test='btn-back-step-address'
                >
                  Back
                </Button>
              )}

              <Button
                type='primary'
                size='large'
                onClick={handleClickNextStep}
                disabled={disabledNextStep()}
                automate-test='btn-save-step-address'
              >
                {order?._id ? 'Save Changes' : 'Next'}
              </Button>
            </div>
          )
        }}
      </Form.Item>
    </>
  )
}

export default StepSelectAddress
