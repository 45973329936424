import { useEffect, useState } from 'react'
import { LoadingOutlined } from '@ant-design/icons'
import { Form, Layout, Spin, message } from 'antd'
import { orderCalculateFreebie, orderSummary, updateOrder } from 'services/order.service'
import { getCorporateById } from 'services/corporate.service'
import { useOrderStore } from 'stores/order'
import { useCorporateStore } from 'stores/corporate'
import { OrderStepTypeEnum } from 'enums/order'

import StepSelectOrder from '../steps/StepSelectOrder'
import StepSelectFeebies from '../steps/StepSelectFreebies'
import StepSelectAddress from '../steps/StepSelectAddress'
import OrderSummary from '../steps/OrderSummary'
import Popup from 'components/common/popup/Popup'

const Content = Layout.Content

const OrderSummaries = () => {
  const [form] = Form.useForm()
  const { order, setOrder, currentStep, setCurrentStep, setCalcualteFreebie } = useOrderStore()
  const { setCorporate } = useCorporateStore()
  const [loading, setLoading] = useState<boolean>(false)
  const [isSuccess, setIsSuccess] = useState<boolean>(false)
  const [isSummaryError, setIsSummaryError] = useState<boolean>(false)

  useEffect(() => {
    if (order?._id) {
      form.setFieldsValue({
        ...order,
      })
      findCorporateById(order?.corporate?._id)
      checkCalculateFreebie()
    } else {
      setIsSummaryError(true)
    }
  }, [])

  const findCorporateById = async (corporateId) => {
    const payload = await getCorporateById(corporateId)
    if (payload?.status.code === 200) {
      setCorporate(payload?.data)
      setCurrentStep(4)
    }
  }

  const checkSummary = async () => {
    const orderItems = form.getFieldValue('orderItems').map((item) => {
      return {
        _id: item._id,
        quantity: item.quantity,
        amount: item.amount,
      }
    })
    const freebieItems = form.getFieldValue('freebieItems').map((item) => {
      return {
        _id: item._id,
        quantity: item.quantity,
        amount: item.amount,
      }
    })
    const body = {
      ...order,
      information: {
        contactName: order?.information.contactName,
        email: order?.information.email,
        phoneNumber: order?.information.phoneNumber,
      },
      orderItems,
      freebieItems,
      freebieRate: order?.freebieRate + '',
      isAcceptFreebie: form.getFieldValue('isAcceptFreebie'),
    }

    if (form.getFieldValue('isAcceptFreebie')) {
      return orderSummary(body)
        .then(() => {
          setIsSummaryError(false)
          setOrder(body)
          return body
        })
        .catch(() => {
          setIsSummaryError(true)
        })
    }

    return body
  }

  const onFinish = async () => {
    const body = await checkSummary()
    setLoading(true)
    updateOrder({
      ...body,
      orderId: body?._id,
    })
      .then((payload) => {
        if (payload.status.code === 200) {
          setIsSuccess(true)
          setOrder(payload.data)
        }
        setLoading(false)
      })
      .catch((error) => {
        message.error('Samething went wrong')
        console.error(error)
        setLoading(false)
      })
  }

  const onFinishFailed = (error) => {
    console.log(error)
  }

  const checkCalculateFreebie = async () => {
    try {
      if (order?.freebieItems?.length) {
        const body = {
          corporateId: order?.corporateId,
          productCatalogId: order?.productCatalogId,
          orderItems: order?.orderItems?.map((item) => {
            return {
              _id: item._id,
              quantity: item.quantity,
              amount: item.amount,
            }
          }),
        }
        const payload = await orderCalculateFreebie(body)
        if (payload?.status.code === 200) {
          setCalcualteFreebie({
            freebieRate: payload?.data.freebieRate,
            totalReceivedFreebie: payload?.data.totalReceivedFreebie,
          })
        }
      }
    } catch (error) {
      console.log(error)
    }
  }

  const handleOk = () => {
    setCurrentStep(4)
    setIsSuccess(false)
  }

  const handlePopupSummary = () => {
    setIsSummaryError(false)
    window.location.reload()
  }

  return (
    <div className='bg-white p-6'>
      <Content>
        <div className='w-2/3 m-auto m'>
          <div className='steps-content mt-10'>
            <Form
              form={form}
              name='order-form'
              layout='vertical'
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              autoComplete='off'
              requiredMark={false}
              scrollToFirstError
              initialValues={{
                isAcceptFreebie: true,
              }}
            >
              <Spin
                tip='Loading...'
                spinning={loading}
                indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />}
              >
                {currentStep !== OrderStepTypeEnum.STEP_SUMMARY ? (
                  <>
                    {currentStep !== OrderStepTypeEnum.STEP_ADDRESS && (
                      <>
                        <div
                          className={`${currentStep !== OrderStepTypeEnum.STEP_ORDER && 'hidden'}`}
                        >
                          <StepSelectOrder form={form} />
                        </div>

                        <div
                          className={`${
                            currentStep !== OrderStepTypeEnum.STEP_FREEBIE && 'hidden'
                          }`}
                        >
                          <StepSelectFeebies form={form} />
                        </div>
                      </>
                    )}

                    <div>
                      {currentStep === OrderStepTypeEnum.STEP_ADDRESS && (
                        <StepSelectAddress form={form} />
                      )}
                    </div>
                  </>
                ) : (
                  <OrderSummary form={form} />
                )}
              </Spin>
            </Form>
          </div>
        </div>
      </Content>

      <Popup
        title='บันทึกการเปลี่ยนแปลงแล้ว'
        message='คุณได้อัพเดดข้อมูลใบสั่งซื้อสำเร็จ'
        isOpen={isSuccess}
        okText='ตกลง'
        onOk={handleOk}
        status='success'
        width={400}
      />

      <Popup
        title='ระบบมีปัญหา'
        message={<div className='text-xl font-bold'>กรุณาทำรายการใหม่อีกครั้ง</div>}
        isOpen={isSummaryError}
        onOk={handlePopupSummary}
        status='error'
        width={400}
      />
    </div>
  )
}

export default OrderSummaries
