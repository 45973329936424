import './Input.scss'
import { InputNumber } from 'antd'
import { useState } from 'react'
import { formatNumber } from 'utilities/common'

interface IProps {
  value?: any
  onChange?: (value: any) => void
  placeholder: string
  attr?: string
}

const NumberInput = ({ value = '', onChange, placeholder, attr = '' }: IProps) => {
  const [number, setNumber] = useState(value)
  const triggerChange = (changedValue) => {
    onChange?.(changedValue)
  }

  const onInputChange = (newValue: string | number | null) => {
    const newNumber = parseInt(newValue + '' || '0')

    if (newNumber) {
      if (Number.isNaN(number)) {
        return
      }

      setNumber(newNumber)
      triggerChange(newValue)
    } else {
      setNumber(null)
      triggerChange(null)
    }
  }

  return (
    <div className='lotus-input'>
      <InputNumber
        size='large'
        placeholder={placeholder}
        value={number}
        onChange={onInputChange}
        formatter={(value) => (value ? formatNumber(value) : '')}
        controls={false}
        className='w-full rounded-lg'
        automate-test={attr}
      />
      <span className='lotus-input-placeholder'>{placeholder}</span>
    </div>
  )
}

export default NumberInput
