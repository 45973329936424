import { Col, Form, Input, Row, Select } from 'antd'
import type { FormInstance } from 'antd/es/form/Form'
import { OrderDeliveryTypeEnum, ProductCatalogTypeEnum } from 'enums/order'
import { useEffect, useState } from 'react'
import { getAddress } from 'services/common.service'
import { useOrderStore } from 'stores/order'

import ContentPanel from 'components/common/panel/PanalContent'

const { Option } = Select
interface IProps {
  form: FormInstance
}

const FormDeliveryAddress = ({ form }: IProps) => {
  const { order, setOrder } = useOrderStore()

  const [address, setAddress] = useState<any>([])
  const [districts, setDistricts] = useState<any>([])
  const [subDistricts, setSubDistricts] = useState<any>([])

  useEffect(() => {
    findAddress()
  }, [])

  const findAddress = async () => {
    const payload = await getAddress()
    if (payload.status?.code === 200) {
      setAddress(payload.data)
    }
  }

  const handleChangeProvince = (provinceName) => {
    const findProvince = address.find((f) => f.name_th === provinceName)
    if (findProvince) {
      setDistricts(findProvince.districts)
      setSubDistricts([])
      form.resetFields([
        ['deliveryAddress', 'district'],
        ['deliveryAddress', 'subdistrict'],
        ['deliveryAddress', 'postalCode'],
      ])
      setOrder({
        ...order,
        deliveryAddress: {
          ...order?.deliveryAddress,
          province: findProvince.name_th,
        },
      })
    }
  }

  const handleChangeDistrict = (districtName) => {
    const findDistrict = districts.find((f) => f.name_th === districtName)
    if (findDistrict) {
      setSubDistricts(findDistrict.subdistricts)
      form.resetFields([
        ['deliveryAddress', 'subdistrict'],
        ['deliveryAddress', 'postalCode'],
      ])
      setOrder({
        ...order,
        deliveryAddress: {
          ...order?.deliveryAddress,
          district: findDistrict.name_th,
        },
      })
    }
  }

  const handleChangeSubDistrict = (subDistrictName) => {
    const findSubDistrict = subDistricts.find((f) => f.name_th === subDistrictName)
    if (findSubDistrict) {
      form.setFieldValue(['deliveryAddress', 'postalCode'], findSubDistrict.postcode)
      setOrder({
        ...order,
        deliveryAddress: {
          ...order?.deliveryAddress,
          subdistrict: findSubDistrict.name_th,
          postalCode: findSubDistrict.postcode,
        },
      })
    }
  }

  const handleChangeStreetAddress = (event) => {
    setOrder({
      ...order,
      deliveryAddress: {
        ...order?.deliveryAddress,
        streetAddress1: event?.target.value,
      },
    })
  }

  return (
    <ContentPanel title='Delivery Detail'>
      <Row gutter={16}>
        <Col span={24}>
          <div className='text-lg font-bold mb-3'>ข้อมูลผู้รับ</div>
        </Col>
        <Col span={24}>
          <Form.Item
            name={['information', 'contactName']}
            rules={[{ required: true, message: 'please input information name' }]}
          >
            <Input size='large' placeholder='ชื่อผู้รับ' />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            name={['information', 'phoneNumber']}
            rules={[{ required: true, message: 'Please input phone number' }]}
          >
            <Input size='large' placeholder='เบอร์ติดต่อ' />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            name={['information', 'email']}
            rules={[
              { required: true, message: 'Please input information email' },
              { type: 'email', message: 'The input is not valid email!' },
            ]}
          >
            <Input size='large' placeholder='อีเมล' />
          </Form.Item>
        </Col>
      </Row>

      {form.getFieldValue('productCatalogType') === ProductCatalogTypeEnum.GIFT_CARD &&
        form.getFieldValue('deliveryType') === OrderDeliveryTypeEnum.DELIVERY && (
          <>
            <div className='border border-top border-solid border-secondary-100 my-4'></div>
            <Row gutter={16}>
              <Col span={24}>
                <div className='text-lg font-bold mb-3'>สถานที่จัดส่ง</div>
              </Col>
              <Col span={24}>
                <Form.Item
                  name={['deliveryAddress', 'streetAddress1']}
                  rules={[{ required: true, message: 'please input delivery address' }]}
                >
                  <Input
                    className='w-full'
                    size='large'
                    placeholder='ที่อยู่'
                    onChange={handleChangeStreetAddress}
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  name={['deliveryAddress', 'province']}
                  rules={[{ required: true, message: 'please select province' }]}
                >
                  <Select
                    className='w-full'
                    size='large'
                    placeholder='จังหวัด'
                    onChange={handleChangeProvince}
                    showSearch
                    optionFilterProp='children'
                  >
                    {address?.length &&
                      address.map((item) => {
                        return (
                          <Option key={item.id} value={item.name_th}>
                            {item.name_th}
                          </Option>
                        )
                      })}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  name={['deliveryAddress', 'district']}
                  rules={[{ required: true, message: 'please select district' }]}
                >
                  <Select
                    className='w-full'
                    size='large'
                    placeholder='เขต/อำเภอ'
                    onChange={handleChangeDistrict}
                    showSearch
                    optionFilterProp='children'
                    disabled={!districts.length}
                  >
                    {districts?.length &&
                      districts.map((item: any) => {
                        return (
                          <Option key={item.id} value={item.name_th}>
                            {item.name_th}
                          </Option>
                        )
                      })}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  name={['deliveryAddress', 'subdistrict']}
                  rules={[{ required: true, message: 'please select subdistrict' }]}
                >
                  <Select
                    className='w-full'
                    size='large'
                    placeholder='แขวง/ตำบล'
                    onChange={handleChangeSubDistrict}
                    showSearch
                    optionFilterProp='children'
                    disabled={!subDistricts.length}
                  >
                    {subDistricts?.length &&
                      subDistricts.map((item: any) => {
                        return (
                          <Option key={`subdistrict-${item.id}`} value={item.name_th}>
                            {item.name_th}
                          </Option>
                        )
                      })}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  name={['deliveryAddress', 'postalCode']}
                  rules={[{ required: true, message: 'please select postalCode' }]}
                >
                  <Input className='w-full' size='large' placeholder='รหัสไปรษณีย์' />
                </Form.Item>
              </Col>
            </Row>
          </>
        )}
    </ContentPanel>
  )
}

export default FormDeliveryAddress
