import { useEffect, useState } from 'react'
import { Alert, Button, Col, Input, Modal, Row, Spin } from 'antd'
import { LoadingOutlined } from '@ant-design/icons'
import { useOrderStore } from 'stores/order'

import DropUpload from 'components/common/upload/DropUpload'
import { uploadOrderFile } from 'services/order.service'
import { OrderUploadTypeEnum } from 'enums/order'

interface IProps {
  isOpen: boolean
  onOk: (value: any) => void
  onCancel: () => void
}

const ModalUploadInvoice = ({ isOpen, onOk, onCancel }: IProps) => {
  const { order, setOrder } = useOrderStore()
  const [filesUpload, setFilesUpload] = useState<any>([])
  const [invoiceNumber, setInvoiceNumber] = useState<string>('')

  const [loading, setLoading] = useState<boolean>(false)
  const [isErrorUpload, setIsErrorUpload] = useState<boolean>(false)
  const [isEnableSaveButton, setIsEnableSaveButton] = useState<boolean>(false)

  useEffect(() => {
    if (order?.information?.invoice) {
      setFilesUpload([order?.information?.invoice])
      setInvoiceNumber(order?.information?.invoice.invoiceNumber)
    }
  }, [])

  const onUpload = (fileItem) => {
    setFilesUpload((prevFiles) => {
      return [...prevFiles, fileItem]
    })
    setIsEnableSaveButton(true)
  }

  const onDelete = (fileItem) => {
    if (fileItem?.imageUrl) {
      const files = filesUpload.filter((f) => f?.file?.uid !== fileItem?.file?.uid)
      setFilesUpload(files)
    }
    if (fileItem?.url) {
      const files = filesUpload.filter((f) => f?.path !== fileItem?.path)
      setFilesUpload(files)
    }
  }

  const handleOnOk = async () => {
    setIsErrorUpload(false)
    setLoading(true)
    try {
      const formData = new FormData()
      formData.append('type', OrderUploadTypeEnum.INVOICE)
      formData.append('orderId', `${order?._id}`)
      formData.append('invoiceNumber', invoiceNumber)
      for (const item of filesUpload) {
        if (item.file) {
          formData.append('file', item.file)
        }
      }

      const payload = await uploadOrderFile(formData)
      if (payload?.status?.code === 200) {
        setOrder({
          information: {
            ...order?.information,
            invoice: {
              ...order?.information.invoice,
              originalFileName: payload.data.originalFileName,
              path: payload.data.path,
              url: payload.data.url,
              invoiceNumber: invoiceNumber,
            },
          },
        })
        onOk('success')
      }
    } catch (error) {
      console.log(error)
      setIsErrorUpload(true)
      scrollToElement('alert-error')
    }
    setLoading(false)
  }

  const scrollToElement = (id) => {
    setTimeout(() => {
      const element = document.getElementById(id)
      if (element) {
        element.scrollIntoView({ block: 'start', behavior: 'smooth' })
      }
    }, 500)
  }

  const handleChangeInvoiceNumber = (event: any) => {
    setInvoiceNumber(event?.target.value)
    setIsEnableSaveButton(true)
  }

  return (
    <Modal
      title='อัปโหลดใบแจ้งหนี้'
      open={isOpen}
      onCancel={onCancel}
      footer={null}
      centered
      width={678}
      bodyStyle={{ padding: '15px' }}
    >
      <div className='max-h-[500px] overflow-auto'>
        <div>
          <Input
            size='large'
            placeholder='หมายเลขใบแจ้งหนี้'
            value={invoiceNumber}
            onChange={handleChangeInvoiceNumber}
          />
        </div>
        <br />
        <div className='border border-solid border-secondary-100 rounded-lg p-4 mb-4'>
          {!!isErrorUpload && (
            <div id='alert-error'>
              <Alert
                type='error'
                showIcon
                description={
                  <div className='text-xs text-red-600 mt-1'>
                    เกิดข้อผิดพลาดขณะอัปโหลด กรุณากดปุ่ม “อัปโหลด” อีกครั้ง
                    เพื่อส่งข้อมูลการอัปโหลดไฟล์
                  </div>
                }
                className='my-2 border-none'
              />
            </div>
          )}

          <Row gutter={16} className='mb-2'>
            <Col span={24}>
              <div className='text-lg font-bold'>อัปโหลดใบแจ้งหนี้</div>
            </Col>
          </Row>
          <div className='my-2'>
            <Spin spinning={loading} indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />}>
              <DropUpload
                files={filesUpload}
                onUpload={onUpload}
                onDelete={onDelete}
                maxCount={1}
                accept='.pdf'
              />
            </Spin>
          </div>
        </div>
      </div>
      <div>
        <div className='flex gap-2 mb-4'>
          <div className='w-1/2'>
            <Button type='default' size='large' block onClick={onCancel} disabled={loading}>
              กลับ
            </Button>
          </div>

          <div className='w-1/2'>
            <Button
              type='primary'
              size='large'
              block
              onClick={handleOnOk}
              disabled={!filesUpload?.length || !invoiceNumber || !isEnableSaveButton}
              loading={loading}
            >
              อัปโหลด
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  )
}

export default ModalUploadInvoice
