import { useEffect, useState } from 'react'
import { Button, Upload, UploadProps } from 'antd'
import { CloseOutlined, CheckCircleFilled, InfoCircleOutlined } from '@ant-design/icons'
import { getFileExtension } from 'utilities/common'

interface IProps {
  files: any[]
  loading?: boolean
  onUpload: (file: any) => void
  onDelete?: (file: any) => void
  maxCount?: number
  maxSize?: number
  accept?: string
}

const { Dragger } = Upload

function validateSize(file, maxSize) {
  const fileSize = file.size / 1024 / 1024 // in MiB
  return fileSize > maxSize ? true : false
}

function getBase64(file, callback) {
  callback({
    file,
    imageUrl: URL.createObjectURL(file),
  })
}

const AlertErrorUploadFileType = ({ accept }: { accept: string }) => (
  <div
    className='bg-black bg-opacity-80 p-4 rounded-md shadow-lg m-auto mb-2'
    style={{ width: 'inherit' }}
  >
    <div className='flex gap-5 items-center text-white'>
      <InfoCircleOutlined className='text-2xl' />
      <div>
        ไม่สามารถอัปโหลดไฟล์สกุลอื่นนอกจาก <br />
        {accept} ได้
      </div>
    </div>
  </div>
)

const AlertLimitSizeUpload = ({ maxSize }: { maxSize: number }) => (
  <div
    className='bg-black bg-opacity-80 p-4 rounded-md shadow-lg m-auto mb-2'
    style={{ width: 'inherit' }}
  >
    <div className='flex gap-5 items-center text-white'>
      <InfoCircleOutlined className='text-2xl' />
      <div>
        ไม่สามารถอัปโหลดไฟล์ได้เนื่องจากเกินขนาด
        <br />
        ที่กำหนด ({maxSize}MB)
      </div>
    </div>
  </div>
)

const AlertLimitCountUpload = ({ maxCount }: { maxCount: number }) => (
  <div
    className='bg-black bg-opacity-80 p-4 rounded-md shadow-lg m-auto mb-2'
    style={{ width: 'inherit' }}
  >
    <div className='flex gap-5 items-center text-white'>
      <InfoCircleOutlined className='text-2xl' />
      <div>
        ไม่สามารถอัปโหลดได้เกินครั้งละ {maxCount} ไฟล์
        <br />
        กรุณาลบไฟล์ที่ไม่เกี่ยวข้อง
        <br />
        ก่อนการอัปโหลดไฟล์ใหม่
      </div>
    </div>
  </div>
)

const BadgeReadyToUpload = () => (
  <div className='flex gap-2'>
    <div className='flex items-center'>
      <div className='relative rounded-full border-2 border-solid border-primary border-opacity-40'>
        <div className='rounded-full h-2 w-2 bg-primary '></div>
      </div>
    </div>
    <div className='text-xs text-secondary-500'>Ready for upload</div>
  </div>
)

const BadgeUploaded = () => (
  <div className='flex gap-2 items-center'>
    <CheckCircleFilled className='text-primary' />
    <div className='text-xs text-primary'>Uploaded</div>
  </div>
)

const DropUpload = ({
  files,
  onUpload,
  onDelete,
  maxCount = 10,
  maxSize = 10,
  accept = 'image/*',
}: IProps) => {
  const [fileList, setFileList] = useState<{ file: File; imageUrl: string }[]>([])
  const [isErrorUploadFileType, setIsErrorUploadFileType] = useState<boolean>(false)
  const [isLimitSizeUpload, setIsLimitSizeUpload] = useState<boolean>(false)
  const [isLimitCountUpload, setIsLimitCountUpload] = useState<boolean>(false)

  const [fileListUpload, setFileListUpload] = useState<File[]>([])

  let checkFile: any = []
  const props: UploadProps = {
    name: 'file',
    multiple: true,
    showUploadList: false,
    maxCount,
    accept,
    beforeUpload(file) {
      setFileListUpload((prev) => {
        return [...prev, file]
      })
    },
    onChange(info) {
      const { status } = info.file

      if (status !== 'uploading') {
        checkFile = [...checkFile, info.file]
        if ([...fileList, ...checkFile].length > maxCount) {
          setIsLimitCountUpload(true)
          return
        }

        if (fileListUpload?.length > maxCount) {
          setIsLimitCountUpload(true)
        }

        getBase64(info.file.originFileObj, (cbResult) => {
          if (validateSize(cbResult.file, maxSize)) {
            setIsLimitSizeUpload(true)
            return
          }

          onUpload(cbResult)
        })
      }
    },
    onDrop(e) {
      if (e.dataTransfer.files?.length) {
        const extensionAll = accept.replace(/\./g, '').split(',')
        const fileTypes = Array.from(e.dataTransfer.files).find((item) => {
          const fileName = item.name.split('.').pop()
          return !extensionAll.includes(fileName!)
        })
        if (fileTypes) {
          setIsErrorUploadFileType(true)
          return
        }
      }
    },
    customRequest: ({ onSuccess }: any) => {
      setTimeout(() => {
        onSuccess('ok')
      }, 0)
    },
  }

  useEffect(() => {
    checkFile = []
    setFileList(files)
    setFileListUpload([])
  }, [files?.length])

  useEffect(() => {
    if (isErrorUploadFileType) {
      scrollToElement('alert-upload-error')
      setTimeout(() => {
        setIsErrorUploadFileType(false)
      }, 3000)
    }
    if (isLimitSizeUpload) {
      scrollToElement('alert-upload-error')
      setTimeout(() => {
        setIsLimitSizeUpload(false)
      }, 3000)
    }
    if (isLimitCountUpload) {
      scrollToElement('alert-upload-error')
      setTimeout(() => {
        setIsLimitCountUpload(false)
        setFileListUpload([])
      }, 3000)
    }
  }, [isErrorUploadFileType, isLimitSizeUpload, isLimitCountUpload])

  const onRemove = (file) => {
    if (fileList?.length) {
      if (onDelete) {
        onDelete(file)
      }
    }
  }

  const getExtension = (item: any) => {
    let filename = item?.path
    if (item?.imageUrl) {
      filename = item?.file?.name
    }
    return getFileExtension(filename)
  }

  const scrollToElement = (id) => {
    setTimeout(() => {
      const element = document.getElementById(id)
      if (element) {
        element.scrollIntoView({ block: 'start', behavior: 'smooth' })
      }
    }, 100)
  }

  return (
    <div className='border border-dashed border-secondary-100'>
      <div className=' w-4/5 my-4 mx-auto'>
        {!!fileList?.length &&
          fileList?.map((item: any, index: number) => {
            return (
              <div
                key={`file-${index}`}
                className='flex gap-4 items-center p-2 my-2 relative rounded-md border border-secondary-100 border-solid shadow-md'
              >
                <a href={item?.imageUrl || item?.url} target='_blank' rel='noreferrer'>
                  <img
                    src={`/assets/images/icons/icon-${getExtension(item)}.svg`}
                    loading='lazy'
                    alt='image-icon'
                    className='h-[50px] w-[50px] border border-secondary-100 border-solid object-cover hover:scale-110 transition duration-300 ease-in-out'
                  />
                </a>
                <div className='flex justify-between w-11/12'>
                  <div className=''>
                    <div className='text-xs underline font-bold mb-1'>
                      <a href={item?.imageUrl || item?.url} target='_blank' rel='noreferrer'>
                        {item?.file?.name || item?.originalFileName}
                      </a>
                    </div>
                    {item?.imageUrl ? <BadgeReadyToUpload /> : <BadgeUploaded />}
                  </div>
                  <div className='absolute right-2 top-1' onClick={() => onRemove(item)}>
                    <CloseOutlined className='cursor-pointer hover:text-primary hover:scale-110 transition duration-300 ease-in-out' />
                  </div>
                </div>
              </div>
            )
          })}
        {!!isErrorUploadFileType && <AlertErrorUploadFileType accept={accept} />}
        {!!isLimitSizeUpload && <AlertLimitSizeUpload maxSize={maxSize} />}
        {!!isLimitCountUpload && <AlertLimitCountUpload maxCount={maxCount} />}
        <div id='alert-upload-error'></div>
      </div>
      {!!(fileList?.length < maxCount) && (
        <Dragger {...props} className='!bg-white !border-secondary !border-none'>
          <div className='flex gap-2 justify-center items-center'>
            <div>
              <span className='text-secondary-300'>ลากไฟล์มาวางที่นี่ หรือ </span>
              <Button type='ghost' className='!bg-white border border-solid border-secondary-100'>
                อัปโหลด
              </Button>
            </div>
          </div>
        </Dragger>
      )}
    </div>
  )
}

export default DropUpload
