const STORAGE_KEY = 'b2b-giftcard-web-cms'

export const STORAGE_AUTH = `${STORAGE_KEY}.app.auth`
export const STORAGE_INFO = `${STORAGE_KEY}.app.info`
export const AUTHEN = {
  ACCESS_TOKEN: `${STORAGE_KEY}.access.token`,
  REFRESH_TOKEN: `${STORAGE_KEY}.refresh.token`,
}

export const STORAGE = {
  WEB_CONFIG: `${STORAGE_KEY}.web.config`,
}
