import { Link } from 'react-router-dom'
import { createElement, useEffect, useState } from 'react'
import { Layout, Menu, MenuProps } from 'antd'
import {
  UnorderedListOutlined,
  BarChartOutlined,
  RightOutlined,
  LeftOutlined,
} from '@ant-design/icons'
import { useGlobalStore } from '../../../stores/global'
import { useAuth } from 'stores/authorized'
import { ROLE_ACCESS } from 'constants/accessRole'

const { Sider } = Layout

const LinkMenu = ({ path, name }: { path: string; name: string }) => (
  <Link to={path}>
    <span>{name}</span>
  </Link>
)

const items: MenuProps['items'] = [
  {
    key: 'Order',
    icon: <UnorderedListOutlined />,
    label: <LinkMenu path='/orders' name='Order' />,
  },
]

const Sidebar = () => {
  const { collapsed, setCollapsed } = useGlobalStore()
  const { auth } = useAuth()
  const [menuItems, setMenuItems] = useState(items)

  useEffect(() => {
    if (ROLE_ACCESS.REPORT.includes(auth?.user?.role)) {
      setMenuItems([
        ...items,
        {
          key: 'Reports',
          icon: <BarChartOutlined />,
          label: <LinkMenu path='/reports' name='Reports' />,
        },
      ])
    }
  }, [])

  return (
    <Sider
      collapsible
      collapsed={collapsed as boolean}
      onCollapse={() => setCollapsed(!collapsed)}
      trigger={null}
      id='app-sidenav'
    >
      <div className='flex h-full items-center absolute w-[18px] -right-4'>
        <div
          className='flex items-center bg-white h-12 w-[18px] rounded-r-lg cursor-pointer group'
          onClick={() => setCollapsed(!collapsed)}
        >
          {createElement(collapsed ? RightOutlined : LeftOutlined, {
            className: 'trigger !top-0 group-hover:scale-125 transition duration-300 ease-in-out',
          })}
        </div>
      </div>
      <div className='sidenav-content'>
        <Menu theme='dark' mode='inline' items={menuItems} />
      </div>
      <div className='sidenav-footer'>v{process.env.REACT_APP_VERSION}</div>
    </Sider>
  )
}

export default Sidebar
