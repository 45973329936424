import fetch from '../utilities/fetchAxios'

export const getProductCatalogs = async () => {
  const method = 'GET'
  const path = '/product-catalog/v1/list'
  return fetch(method, path)
}

export const getProductCatalogฺById = async (id: string | number) => {
  const method = 'GET'
  const path = `/product-catalog/v1/${id}`
  return fetch(method, path)
}
