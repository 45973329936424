import qs from 'qs'
import { StaffLoginResponse } from 'stores/types/authorized.types'
import fetch from '../utilities/fetchAxios'

export const staffLogin = async (body) => {
  const method = 'POST'
  const path = '/auth/v1/login/staff'
  return fetch(method, path, body) as Promise<StaffLoginResponse>
}

export const rediectOneLogin = () => {
  const authorizationEndpointUrl = `${
    process.env.REACT_APP_ONELOGIN_URL
  }/oidc/2/auth?${qs.stringify({
    ['client_id']: `${process.env.REACT_APP_ONELOGIN_CLIENT_ID}`,
    ['response_type']: 'code',
    ['redirect_uri']: `${process.env.REACT_APP_CMS_REDIRECT_URI}`,
    ['scope']: 'openid email profile groups params',
  })}`
  return authorizationEndpointUrl
}
