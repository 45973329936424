import { useNavigate } from 'react-router-dom'
import { Layout, PageHeader } from 'antd'
import { useGlobalStore } from '../../../stores/global'

const isDisableLayout = false

const Content = ({ children }: any) => {
  const navigate = useNavigate()
  const {
    pageHeader: { title, subTitle, onBack, backIcon, extra },
  } = useGlobalStore()
  return (
    <>
      {isDisableLayout ? (
        <>
          <Layout.Content
            id='app-content'
            className='site-layout-background'
            style={{
              minHeight: 280,
            }}
          >
            {children}
          </Layout.Content>
        </>
      ) : (
        <>
          <div className='px-6'>
            {onBack ? (
              <PageHeader
                onBack={() => (onBack ? navigate(onBack) : navigate(-1))}
                backIcon={backIcon}
                title={title}
                subTitle={subTitle}
                extra={extra}
              />
            ) : (
              <PageHeader title={title} subTitle={subTitle} extra={extra} />
            )}
          </div>

          <Layout.Content
            id='app-content'
            className='site-layout-background'
            style={{
              minHeight: 280,
            }}
          >
            {children}
          </Layout.Content>
        </>
      )}
    </>
  )
}
export default Content
