import { OrderStepTypeEnum } from 'enums/order'
import { create } from 'zustand'
import { CalculateFreebie, Order } from './types/order.types'

type State = {
  currentStep: OrderStepTypeEnum
  setCurrentStep: (currentStep: OrderStepTypeEnum) => void

  order: Order | null
  setOrder: (order: any) => void

  calucateFreebie: CalculateFreebie
  setCalcualteFreebie: (freebieItem: CalculateFreebie) => void
}

export const useOrderStore = create<State>((set) => ({
  order: null,
  setOrder: (order) =>
    set((state) => ({
      order:
        order === null
          ? null
          : {
              ...state.order,
              ...order,
            },
    })),

  calucateFreebie: {
    freebieRate: 0,
    totalReceivedFreebie: 0,
  },
  setCalcualteFreebie: (freebieItem) =>
    set(() => ({
      calucateFreebie: freebieItem,
    })),

  currentStep: OrderStepTypeEnum.STEP_CORPORATE,
  setCurrentStep: (currentStep) =>
    set(() => ({
      currentStep,
    })),
}))
