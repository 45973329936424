import { Col, Row } from 'antd'
import { formatNumber } from 'utilities/common'
import { calculateSum, calculateSumItems } from 'utilities/calculate'
import { OrderItems } from 'stores/types/order.types'

interface IProps {
  items: OrderItems[]
  catalogName: string | undefined
  notFund?: string
}
const TableSummaryItems = ({ items, catalogName, notFund = 'No item selected' }: IProps) => {
  const orderItems = calculateSumItems(items, 'amount', 'quantity')
  return (
    <>
      {orderItems?.map((item, index) => {
        return (
          <Row
            key={`summary-${index}-${item?._id}`}
            gutter={16}
            className='text-secondary-500 text-lg'
            automate-test='row-item'
          >
            <Col className='py-2' span={8}>
              {item?.amount ? `${catalogName} ฿${formatNumber(item?.amount)}` : notFund}
            </Col>
            <Col className='py-2 text-right' span={8}>
              Qty: {formatNumber(item?.quantity || 0)}
            </Col>
            <Col className='py-2 text-right' span={8}>
              THB: {formatNumber(item?.total, 2)}
            </Col>
          </Row>
        )
      })}

      <Row
        gutter={16}
        className='border-t border-solid border-secondary-100 text-lg font-semibold mt-4'
      >
        <Col className='py-3' span={8}>
          Subtotal
        </Col>
        <Col className='py-3 text-right' span={8} automate-test='total-quantity'>
          Qty: {formatNumber(calculateSum(orderItems, 'quantity'))}
        </Col>
        <Col className='py-3 text-right' span={8} automate-test='total-summary'>
          THB: {formatNumber(calculateSum(orderItems, 'total'), 2)}
        </Col>
      </Row>
    </>
  )
}

export default TableSummaryItems
