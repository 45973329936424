import { Button, Col, Row } from 'antd'
import type { FormInstance } from 'antd/es/form/Form'
import { useEffect, useState } from 'react'
import { orderCalculateFreebie, orderSummary } from 'services/order.service'
import { useCorporateStore } from 'stores/corporate'
import { useOrderStore } from 'stores/order'
import { useProductCatalogStore } from 'stores/productCatalog'
import { formatPhoneNumber, mergeStringArray } from 'utilities/common'
import { isAllowUpdateOrderCollection, isAllowUpdateOrderSummary } from 'utilities/rules'

import Popup from 'components/common/popup/Popup'
import ContentPanel from 'components/common/panel/PanalContent'
import TableSummaryItems from 'components/features/order/components/TableOrderItems'
import { OrderDeliveryTypeEnum, OrderStepTypeEnum } from 'enums/order'
import { useAuth } from 'stores/authorized'
import { ROLE_ACCESS } from 'constants/accessRole'

interface IProps {
  form: FormInstance
}

const OrderSummary = ({ form }: IProps) => {
  const { auth } = useAuth()
  const { order, setOrder, setCurrentStep, setCalcualteFreebie } = useOrderStore()
  const { corporate } = useCorporateStore()
  const { catalog } = useProductCatalogStore()

  const [deliveryAddress, setDeliveryAddress] = useState<string>()
  const [billingAddress, setBillingAddress] = useState<string>()
  const [isSummaryError, setIsSummaryError] = useState<boolean>(false)

  useEffect(() => {
    if (corporate) {
      const billing = mergeStringArray([
        corporate?.streetAddress1,
        corporate?.subdistrict,
        corporate?.district,
        corporate?.province,
        corporate?.postalCode,
      ])
      setBillingAddress(billing)
    }

    if (order?.deliveryAddress) {
      let delivery = mergeStringArray([
        order?.deliveryAddress?.streetAddress1,
        order?.deliveryAddress?.subdistrict,
        order?.deliveryAddress?.district,
        order?.deliveryAddress?.province,
        order?.deliveryAddress?.postalCode,
      ])
      if (order?.deliveryType === OrderDeliveryTypeEnum.PICKUP) {
        delivery = order?.deliveryAddress?.streetAddress1
      }
      setDeliveryAddress(delivery)
    }

    if (!order?._id) {
      checkSummary()
    }
  }, [])

  const checkSummary = async () => {
    try {
      const body = {
        ...order,
        freebieRate: order?.freebieRate + '',
      }

      const payload = await orderSummary(body)
      if (payload?.status?.code === 200) {
        setIsSummaryError(false)
        setOrder(body)
      } else {
        setIsSummaryError(true)
      }
    } catch (err: any) {
      setIsSummaryError(true)
    }
  }

  const handlePopupOk = () => {
    setIsSummaryError(false)
    let step = OrderStepTypeEnum.STEP_CORPORATE
    if (order?._id) {
      step = OrderStepTypeEnum.STEP_SUMMARY
    }

    setCurrentStep(step)
  }

  const handleEditFreebie = async () => {
    if (order?._id) {
      await checkCalculateFreebie()
    }
    setCurrentStep(OrderStepTypeEnum.STEP_FREEBIE)
  }

  const checkCalculateFreebie = async () => {
    try {
      const freebieRate = 0
      const body = {
        corporateId: order?.corporateId,
        productCatalogId: order?.productCatalogId,
        orderItems: order?.orderItems?.map((item) => {
          return {
            _id: item._id,
            quantity: item.quantity,
            amount: item.amount,
          }
        }),
      }
      const payload = await orderCalculateFreebie(body)
      if (payload?.status.code === 200) {
        setCalcualteFreebie({
          freebieRate: payload?.data.freebieRate,
          totalReceivedFreebie: payload?.data.totalReceivedFreebie,
        })
        return payload?.data.freebieRate
      }
      return freebieRate
    } catch (error) {
      console.log(error)
    }
  }

  const allowEditButton = (step) => {
    // role permossion
    if (!ROLE_ACCESS.ORDER_DETAIL.EDIT.includes(auth?.user?.role)) {
      return undefined
    }

    switch (step) {
      case 'order':
        return (order?._id && isAllowUpdateOrderSummary(order?.status)) || !order?._id
          ? () => setCurrentStep(OrderStepTypeEnum.STEP_ORDER)
          : undefined
      case 'freebie':
        return (order?._id && isAllowUpdateOrderSummary(order?.status)) || !order?._id
          ? () => handleEditFreebie()
          : undefined
      case 'address':
        return (order?._id && isAllowUpdateOrderCollection(order?.status)) || !order?._id
          ? () => setCurrentStep(OrderStepTypeEnum.STEP_ADDRESS)
          : undefined
    }
  }

  return (
    <>
      <ContentPanel
        title='Corporate Information'
        bodySolid={true}
        onClick={order?._id ? undefined : () => setCurrentStep(OrderStepTypeEnum.STEP_CORPORATE)}
      >
        <Row gutter={16} className='text-lg'>
          <Col span={8} className='mb-3'>
            Corporate No.
          </Col>
          <Col span={16} className='font-semibold'>
            {corporate?.corporateNumber}
          </Col>
          <Col span={8} className='mb-3'>
            Corporate Name
          </Col>
          <Col span={16} className='font-semibold'>
            {corporate?.corporateName?.th}
          </Col>
        </Row>
      </ContentPanel>

      {!!order?.orderItems.length && catalog?.type.fullName && (
        <ContentPanel
          attr='step-order'
          title='Order Summary'
          bodySolid={true}
          onClick={allowEditButton('order')}
        >
          <TableSummaryItems items={order?.orderItems} catalogName={catalog?.type.fullName} />
        </ContentPanel>
      )}

      {!!order?.freebieItems?.length && catalog?.type.fullName && (
        <ContentPanel
          attr='step-freebies'
          title='Freebies Summary'
          bodySolid={true}
          onClick={allowEditButton('freebie')}
        >
          <TableSummaryItems items={order?.freebieItems} catalogName={catalog?.type.fullName} />
        </ContentPanel>
      )}

      <ContentPanel
        attr='step-address'
        title='Order Collection'
        bodySolid={true}
        onClick={allowEditButton('address')}
      >
        <Row gutter={16} className='text-lg'>
          <Col span={8} className='mb-3'>
            Delivery type
          </Col>
          <Col span={16} className='font-semibold capitalize'>
            {order?.deliveryType.toLowerCase()}
          </Col>
          <Col span={8} className='mb-3'>
            Recipient’s information
          </Col>
          <Col span={16} className=''>
            <div className='font-semibold'>{order?.information?.contactName}</div>
            <div className=''>{formatPhoneNumber(order?.information?.phoneNumber)}</div>
            <div className=''>{deliveryAddress}</div>
            <div className=''>TAX ID {corporate?.taxId}</div>
          </Col>
        </Row>
      </ContentPanel>

      <ContentPanel title='Billing Information' bodySolid={true}>
        <Row gutter={16} className='text-lg'>
          <Col span={8} className='mb-3'>
            Name.
          </Col>
          <Col span={16} className='font-semibold'>
            {corporate?.corporateName?.th}
          </Col>
          <Col span={8} className='mb-3'>
            TAX ID.
          </Col>
          <Col span={16} className='font-semibold'>
            {corporate?.taxId}
          </Col>
          <Col span={8} className='mb-3'>
            Billing address
          </Col>
          <Col span={16} className='font-semibold'>
            {billingAddress}
          </Col>
        </Row>
      </ContentPanel>

      {!order?._id && (
        <div className='mt-10 flex gap-2 justify-center'>
          <Button
            type='primary'
            size='large'
            onClick={() => form.submit()}
            disabled={isSummaryError}
          >
            Submit Quotation
          </Button>
        </div>
      )}

      <Popup
        title='ระบบมีปัญหา'
        message={<div className='text-xl font-bold'>กรุณาทำรายการใหม่อีกครั้ง</div>}
        isOpen={isSummaryError}
        onOk={handlePopupOk}
        status='error'
        width={400}
      />
    </>
  )
}

export default OrderSummary
