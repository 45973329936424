import { useEffect, useState } from 'react'
import { Button, Col, Form, Input, Row, Checkbox } from 'antd'
import type { FormInstance } from 'antd/es/form/Form'
import { PlusOutlined, MinusCircleOutlined } from '@ant-design/icons'
import { getProductCatalogs } from 'services/productCatalog.service'
import { ProductCatalog } from 'stores/types/productCatalog.types'
import { SelectOption } from 'stores/types/common.type'
import { formatNumber } from 'utilities/common'
import { useProductCatalogStore } from 'stores/productCatalog'
import { useOrderStore } from 'stores/order'
import { orderCalculateFreebie } from 'services/order.service'
import { useCorporateStore } from 'stores/corporate'
import { CorporateGroupTypeEnum } from 'enums/corporate'

import ContentPanel from 'components/common/panel/PanalContent'
import SelectInput from 'components/common/select/Select'
import NumberInput from 'components/common/input/InputNumber'
import Popup from 'components/common/popup/Popup'
import TableSummaryItems from 'components/features/order/components/TableOrderItems'
import { OrderStepTypeEnum } from 'enums/order'

interface IProps {
  form: FormInstance
}
const StepSelectOrder = ({ form }: IProps) => {
  const { corporate } = useCorporateStore()
  const { setCurrentStep, order, setOrder, setCalcualteFreebie } = useOrderStore()
  const { productCatalogs, setProductCatalogs, catalog, setCatalog } = useProductCatalogStore()

  const [catalogTypeOptions, setCatalogTypeOptions] = useState([])
  const [catalogSelectedOptions, setCatalogSelectedOptions] = useState<ProductCatalog>()
  const [isFreebieChange, setIsFreebieChange] = useState<boolean>(false)
  const [isFreebieEmpty, setIsFreebieEmpty] = useState<boolean>(false)

  useEffect(() => {
    findProductCatalogs()

    if (order?._id && catalog) {
      setCatalogSelectedOptions(catalog)
    }
  }, [])

  const findProductCatalogs = async () => {
    const payload = await getProductCatalogs()
    if (payload?.status.code === 200) {
      const catalogs = payload?.data.map((item) => {
        return {
          value: item._id,
          label: item.type.fullName,
        }
      })
      setCatalogTypeOptions(catalogs)
      setProductCatalogs(payload?.data)
    }
  }

  const handleChangeCatalogType = (productCatalogId) => {
    const findCatalog: ProductCatalog | undefined = productCatalogs.find(
      (f: ProductCatalog) => f._id === productCatalogId,
    )
    if (findCatalog) {
      setCatalog(findCatalog)
      setCatalogSelectedOptions(findCatalog)

      form.setFieldsValue({
        productCatalogType: findCatalog.type.value,
        orderItems: [],
        freebieItems: [],
        deliveryType: undefined,
        deliveryAddress: undefined,
        isAcceptFreebie: true,
      })
    }
  }

  const filterProductCatalogOptions = () => {
    let finalOptions: SelectOption[] = []
    if (catalogSelectedOptions?.value?.length) {
      const orderItemIds: string[] = form.getFieldValue('orderItems')?.map((f) => f?._id) ?? []
      finalOptions = catalogSelectedOptions?.value
        .filter((f: { _id: string }) => !orderItemIds.includes(f._id))
        .map((item) => {
          return {
            value: item._id,
            label: `฿${formatNumber(item.amount)}`,
          }
        })
    }
    return finalOptions
  }

  const handleChangeCatalogValue = (selected, index) => {
    const catalogData = catalogSelectedOptions?.value.find((f) => f._id === selected)
    if (catalogData) {
      const catalogValue = form.getFieldValue('orderItems')
      catalogValue[index]._id = catalogData?._id
      catalogValue[index].amount = catalogData?.amount

      form.setFieldValue('orderItems', catalogValue)
    }
  }

  const handleClickNextStep = async () => {
    const orderItems = form.getFieldValue('orderItems')
    const validateOrderItemFields = orderItems.map((_, index) => {
      return [
        ['orderItems', index, '_id'],
        ['orderItems', index, 'amount'],
        ['orderItems', index, 'quantity'],
      ]
    })
    const validateNameList = [
      'productCatalogId',
      'productCatalogType',
      ...validateOrderItemFields.flat(),
    ]
    const formValues = await form.validateFields(validateNameList)
    if (formValues) {
      let nextStep = OrderStepTypeEnum.STEP_ADDRESS

      const olderFreebie = {
        freebieRate: Number(order?.freebieRate) || 0,
        totalReceivedFreebie: Number(order?.freebieTotal) || 0,
      }
      const freebie = await checkCalculateFreebie()
      if (freebie.freebieRate) {
        nextStep = OrderStepTypeEnum.STEP_FREEBIE
      }

      setOrder({
        ...formValues,
        isAcceptFreebie: form.getFieldValue('isAcceptFreebie'),
        _id: order?._id,
      })

      // check for update order
      if (order?._id) {
        handleUpdateSubmit(olderFreebie, freebie)
      } else {
        // check acceptFreebie if checked(false) go to step add address
        if (!form.getFieldValue('isAcceptFreebie')) {
          nextStep = OrderStepTypeEnum.STEP_ADDRESS
        }
        setCurrentStep(nextStep)
      }
    }
  }

  const disabledNextStep = () => {
    return !form.getFieldValue('productCatalogId') || !form.getFieldValue('orderItems')?.length
  }

  const checkCalculateFreebie = async () => {
    try {
      const body = {
        corporateId: form.getFieldValue('corporateId'),
        productCatalogId: form.getFieldValue('productCatalogId'),
        orderItems: form.getFieldValue('orderItems')?.map((item) => {
          return {
            _id: item._id,
            quantity: item.quantity,
            amount: item.amount,
          }
        }),
      }
      const payload = await orderCalculateFreebie(body)
      if (payload?.status.code === 200) {
        setCalcualteFreebie({
          freebieRate: payload?.data.freebieRate,
          totalReceivedFreebie: payload?.data.totalReceivedFreebie,
        })
        return payload?.data
      }
      return {
        freebieRate: 0,
        totalReceivedFreebie: 0,
      }
    } catch (error) {
      console.log(error)
    }
  }

  const handleUpdateSubmit = (olderFreebie, freebie) => {
    if (!form.getFieldValue('isAcceptFreebie')) {
      form.setFieldValue('freebieItems', [])
      setOrder({
        ...order,
        freebieItems: [],
        orderItems: form.getFieldValue('orderItems'),
      })
      form.submit()
      return
    }

    if (olderFreebie?.totalReceivedFreebie !== freebie.totalReceivedFreebie) {
      if (freebie.freebieRate === 0) {
        setIsFreebieEmpty(true)
        return
      }

      setIsFreebieChange(true)
      return
    }

    if (
      freebie.freebieRate !== 0 &&
      olderFreebie?.totalReceivedFreebie === freebie.totalReceivedFreebie &&
      form.getFieldValue('freebieItems').length === 0
    ) {
      setIsFreebieChange(true)
      return
    }

    form.submit()
  }

  const handlePopupFreebieChange = () => {
    form.setFieldValue('freebieItems', [])
    setOrder({
      ...order,
      freebieItems: [],
    })
    setIsFreebieChange(false)
    setCurrentStep(OrderStepTypeEnum.STEP_FREEBIE)
  }

  const handlePopupFreebieEmpty = () => {
    setOrder({
      ...order,
      freebieItems: [],
    })
    form.setFieldValue('freebieItems', [])
    setIsFreebieEmpty(false)
    form.submit()
  }

  return (
    <>
      <ContentPanel title='Select Giftcard Type'>
        <Form.Item name='productCatalogType' hidden>
          <Input hidden />
        </Form.Item>
        <Form.Item
          name='productCatalogId'
          rules={[{ required: true, message: 'please select product catalog' }]}
        >
          <SelectInput
            options={catalogTypeOptions}
            onChange={handleChangeCatalogType}
            placeholder='Giftcard type'
            disabled={!!order?._id}
            attr='select-order-product-catalog'
          />
        </Form.Item>
      </ContentPanel>

      <ContentPanel title='Select Giftcard Value'>
        <Form.Item noStyle shouldUpdate>
          {({ getFieldValue }) => (
            <Form.List name='orderItems'>
              {(fields, { add, remove }) => (
                <>
                  <div className='flex justify-end'>
                    <Button
                      type='default'
                      size='large'
                      icon={<PlusOutlined />}
                      onClick={() => add()}
                      disabled={!getFieldValue('productCatalogId')}
                      automate-test='btn-add-order-item'
                    >
                      Add Item
                    </Button>
                  </div>
                  <Row
                    gutter={16}
                    className='border-b border-solid boder-secondary text-secondary-500 mb-4'
                  >
                    <Col className='py-3' span={4}>
                      #
                    </Col>
                    <Col className='py-3' span={13}>
                      Value
                    </Col>
                    <Col className='py-3' span={5}>
                      Quantity
                    </Col>
                    <Col className='py-3' span={2}></Col>
                  </Row>

                  {fields.map(({ key, name, ...restField }, index) => (
                    <Row gutter={16} key={key} align='middle' className='mb-4'>
                      <Col span={4}>
                        {index + 1}

                        <Form.Item {...restField} name={[name, '_id']} hidden>
                          <Input hidden />
                        </Form.Item>
                      </Col>

                      <Col span={13}>
                        <Form.Item
                          {...restField}
                          name={[name, 'amount']}
                          rules={[{ required: true, message: 'please select card value' }]}
                          className='m-0'
                          help=''
                        >
                          <SelectInput
                            options={filterProductCatalogOptions()}
                            onChange={(value) => handleChangeCatalogValue(value, index)}
                            placeholder='Card value'
                            attr={`select-order-card-value-${index}`}
                          />
                        </Form.Item>
                      </Col>

                      <Col span={5}>
                        <Form.Item
                          {...restField}
                          name={[name, 'quantity']}
                          rules={[
                            {
                              required: true,
                              message: 'Please input quantity.',
                              type: 'number',
                            },
                          ]}
                          className='m-0'
                          help=''
                        >
                          <NumberInput
                            placeholder='Qty.'
                            attr={`input-order-card-quantity-${index}`}
                          />
                        </Form.Item>
                      </Col>
                      <Col span={2}>
                        <MinusCircleOutlined
                          onClick={() => remove(name)}
                          automate-test={`btn-delete-order-item-${index}`}
                        />
                      </Col>
                    </Row>
                  ))}
                </>
              )}
            </Form.List>
          )}
        </Form.Item>
      </ContentPanel>

      <ContentPanel title='Review Summary' shadow={false} className='bg-secondary'>
        <div id='table-summary-order'>
          <Form.Item noStyle shouldUpdate>
            {({ getFieldValue }) => {
              return (
                <TableSummaryItems
                  items={getFieldValue('orderItems')}
                  catalogName={catalog?.type.fullName}
                />
              )
            }}
          </Form.Item>
        </div>
      </ContentPanel>

      {corporate?.corporateGroup?.type === CorporateGroupTypeEnum.ANNUAL && (
        <Form.Item
          name='isAcceptFreebie'
          valuePropName='checked'
          getValueProps={(value) => ({ checked: !value })}
          getValueFromEvent={(event) => !event.target.checked}
        >
          <Checkbox className='text-secondary-500'>ลูกค้าสัญญารายปี ไม่รับของสัมมนาคุณ</Checkbox>
        </Form.Item>
      )}

      <Form.Item shouldUpdate>
        {() => {
          return (
            <div className='mt-10 flex gap-2 justify-center'>
              {order?._id ? (
                <Button
                  type='default'
                  size='large'
                  onClick={() => setCurrentStep(OrderStepTypeEnum.STEP_SUMMARY)}
                  automate-test='btn-cancel-step-order'
                >
                  Cancel
                </Button>
              ) : (
                <Button
                  type='default'
                  size='large'
                  onClick={() => setCurrentStep(OrderStepTypeEnum.STEP_CORPORATE)}
                  automate-test='btn-back-step-order'
                >
                  Back
                </Button>
              )}

              <Button
                type='primary'
                size='large'
                onClick={handleClickNextStep}
                disabled={disabledNextStep()}
                automate-test='btn-save-step-order'
              >
                {order?._id ? 'Save Changes' : 'Next'}
              </Button>
            </div>
          )
        }}
      </Form.Item>

      <Popup
        title='สินค้าแถมมีการเปลี่ยนแปลง'
        message={
          <div className='text-center text-sm mt-2'>
            คุณต้องปรับเปลี่ยนสินค้าแถม <br />
            เนื่องจากมีการปรับเปลี่ยนยอดสั่งซื้อสินค้า{' '}
          </div>
        }
        isOpen={isFreebieChange}
        onOk={handlePopupFreebieChange}
        okText='ปรับเปลี่ยนรายการสินค้าแถม'
        status='error'
        width={400}
      />

      <Popup
        title='ไม่มีสินค้าแถมสำหรับออเดอร์นี้'
        message={
          <div className='text-center text-sm mt-2'>
            คุณจะไม่ได้รับสินค้าแถมจากออเดอร์นี้ <br />
            เนื่องจากมีการปรับเปลี่ยนยอดสั่งซื้อสินค้า
          </div>
        }
        isOpen={isFreebieEmpty}
        onOk={handlePopupFreebieEmpty}
        okText='รับทราบ'
        status='error'
        width={400}
      />
    </>
  )
}

export default StepSelectOrder
