import { useAuth } from 'stores/authorized'

interface IProps {
  children: React.ReactNode
  roles: string[]
  scope?: string[]
  status?: string
}

const PermissionAccess = ({ children, roles, scope = [], status = undefined }: IProps) => {
  const { auth } = useAuth()
  if (scope?.length && status && !scope.includes(status)) {
    return <></>
  }

  if (roles.includes(auth?.user?.role)) {
    return <>{children}</>
  }

  return <></>
}

export default PermissionAccess
