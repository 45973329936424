import './Select.scss'
import { Select } from 'antd'

interface IProps {
  value?: any
  options: any
  onChange?: (value: any) => void
  placeholder: string
  disabled?: boolean
  attr?: string
}

const SelectInput = ({
  value,
  options,
  onChange,
  placeholder,
  disabled = false,
  attr = '',
}: IProps) => {
  const triggerChange = (changedValue) => {
    onChange?.(changedValue)
  }

  const onSelectChange = (newValue) => {
    triggerChange(newValue)
  }

  return (
    <div className='lotus-select'>
      <Select
        className='w-full'
        size='large'
        placeholder={placeholder}
        value={value}
        options={options}
        onChange={onSelectChange}
        disabled={disabled}
        automate-test={attr}
      />
      <span className='lotus-select-placeholder'>{placeholder}</span>
    </div>
  )
}

export default SelectInput
