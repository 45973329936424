import { STORAGE } from 'constants/storageKeys'
import { create } from 'zustand'
import { persist, createJSONStorage } from 'zustand/middleware'
import { PageHeader } from './types/global.types'

type State = {
  collapsed: boolean
  setCollapsed: (toggle: boolean) => void // set toggle menu
  pageHeader: PageHeader
  setPageHeader: (pageHeader: PageHeader) => void
}

type PersistState = {
  appConfig: any
  setAppConfig: ($appConfig: any) => void
}

export const useGlobalStore = create<State>((set) => ({
  collapsed: true,
  setCollapsed: () => set((state) => ({ collapsed: !state.collapsed })),

  pageHeader: {
    title: undefined,
    subTitle: undefined,
    backIcon: undefined,
    onBack: undefined,
    extra: [],
  },
  setPageHeader: (pageHeader) =>
    set((state) => ({
      pageHeader: {
        ...state.pageHeader,
        ...pageHeader,
      },
    })),
}))

export const useAppConfigStore = create(
  persist<PersistState>(
    (set) => ({
      appConfig: {},
      setAppConfig: ($appConfig: any) => {
        set({ appConfig: $appConfig })
      },
    }),
    {
      name: STORAGE.WEB_CONFIG,
      storage: createJSONStorage(() => sessionStorage),
    },
  ),
)
