import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPen } from '@fortawesome/free-solid-svg-icons'
import { Button } from 'antd'

interface IProps {
  children: React.ReactNode
  title?: string | React.ReactNode
  error?: undefined | React.ReactNode
  shadow?: boolean
  className?: string
  onClick?: () => void
  bodySolid?: boolean
  attr?: string
}

const ContentPanel = ({
  children,
  title,
  error,
  shadow = true,
  className = '',
  onClick,
  bodySolid = false,
  attr = '',
}: IProps) => {
  return (
    <>
      <div
        className={`rounded-lg py-10 px-16 mb-6 border border-solid border-secondary-100 ${
          shadow && 'shadow-md'
        } ${className}`}
      >
        {error && <div className='mb-5'>{error}</div>}
        {title && (
          <div className='mb-5 flex justify-between'>
            <h2 className='font-semibold'>{title}</h2>
            {onClick && typeof onClick === 'function' && (
              <div className=''>
                <Button onClick={onClick} type='default' automate-test={`btn-edit-${attr}`}>
                  <FontAwesomeIcon icon={faPen} className='mr-2' /> Edit
                </Button>
              </div>
            )}
          </div>
        )}
        <div
          className={
            bodySolid
              ? 'rounded-lg border border-solid border-secondary-100 p-5 text-secondary-500'
              : ''
          }
        >
          {children}
        </div>
      </div>
    </>
  )
}

export default ContentPanel
