import { useEffect, useState } from 'react'
import { Button, Modal, Select } from 'antd'
import { useOrderStore } from 'stores/order'
import { OrderStatusTypeEnum } from 'enums/order'

interface IProps {
  isOpen: boolean
  onCancel: () => void
  onOk: (status: OrderStatusTypeEnum) => void
}

const ModalUpdateCardStatus = ({ isOpen, onCancel, onOk }: IProps) => {
  const { order } = useOrderStore()
  const [value, setValue] = useState<OrderStatusTypeEnum>()

  useEffect(() => {
    if (
      [
        OrderStatusTypeEnum.CARD_READY,
        OrderStatusTypeEnum.CARD_RECEIVED,
        OrderStatusTypeEnum.CARD_ACTIVATED,
      ].includes(order?.status as OrderStatusTypeEnum)
    ) {
      setValue(order?.status as OrderStatusTypeEnum)
    }
  }, [])

  const onChange = (status: OrderStatusTypeEnum) => {
    setValue(status)
  }

  return (
    <Modal
      title={<div className='font-bold text-xl'>อัปเดตสถานะสินค้า</div>}
      width={700}
      open={isOpen}
      onCancel={onCancel}
      centered
      footer={[
        <Button
          key='submit'
          type='primary'
          size='large'
          block
          disabled={!value}
          onClick={() => onOk(value as OrderStatusTypeEnum)}
        >
          บันทึกสถานะ
        </Button>,
      ]}
    >
      <div>
        <div className='text-secondary-500 text-base mb-2'>กรุณาเลือกสถานะล่าสุด</div>
        <Select
          placeholder='สถานะ'
          size='large'
          className='w-full'
          optionFilterProp='children'
          onChange={onChange}
          filterOption={(input, option) =>
            (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
          }
          value={value}
          options={[
            {
              value: OrderStatusTypeEnum.CARD_READY,
              label: 'ส่งสินค้าแล้ว/พร้อมให้มารับ',
            },
            {
              value: OrderStatusTypeEnum.CARD_RECEIVED,
              label: 'ได้รับสินค้าแล้ว',
            },
            {
              value: OrderStatusTypeEnum.CARD_ACTIVATED,
              label: 'Activate สินค้าแล้ว',
            },
          ]}
        />
      </div>
    </Modal>
  )
}

export default ModalUpdateCardStatus
