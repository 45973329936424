import { useEffect, useState } from 'react'
import { LoadingOutlined } from '@ant-design/icons'
import { Form, Layout, Steps, Spin, message } from 'antd'
import { useNavigate } from 'react-router-dom'
import { createOrder } from 'services/order.service'
import { useGlobalStore } from 'stores/global'
import { useOrderStore } from 'stores/order'
import { useCorporateStore } from 'stores/corporate'
import { useProductCatalogStore } from 'stores/productCatalog'

import StepSelectCorporate from '../../../components/features/order/steps/StepSelectCoporate'
import StepSelectOrder from '../../../components/features/order/steps/StepSelectOrder'
import StepSelectFeebies from '../../../components/features/order/steps/StepSelectFreebies'
import StepSelectAddress from '../../../components/features/order/steps/StepSelectAddress'
import OrderSummary from '../../../components/features/order/steps/OrderSummary'
import Popup from 'components/common/popup/Popup'
import { OrderStepTypeEnum } from 'enums/order'

const Content = Layout.Content
const stepItems = [
  {
    title: <div className='font-bold text-sm'>Add corperate info</div>,
  },
  {
    title: <div className='font-bold text-sm'>Add order detail</div>,
  },
  {
    title: <div className='font-bold text-sm'>Add extra detail</div>,
  },
  {
    title: <div className='font-bold text-sm'>Add address</div>,
  },
  {
    title: (
      <div className='font-bold text-sm'>
        Review order <br />
        summary
      </div>
    ),
  },
]

const CreateOrderPage = () => {
  const navigate = useNavigate()
  const [form] = Form.useForm()
  const { setPageHeader } = useGlobalStore()
  const { order, setOrder, currentStep, setCurrentStep } = useOrderStore()
  const { setCorporate } = useCorporateStore()
  const { setCatalog, setProductCatalogs } = useProductCatalogStore()
  const [loading, setLoading] = useState<boolean>(false)
  const [isSuccess, setIsSuccess] = useState<boolean>(false)

  useEffect(() => {
    setPageHeader({
      title: 'Orders',
      subTitle: 'Create New Quotation',
      onBack: '/orders',
      backIcon: null,
      extra: [],
    })

    resetValues()
  }, [])

  const onFinish = () => {
    setLoading(true)
    const body = order
    createOrder(body)
      .then((payload) => {
        if (payload.status.code === 201) {
          setIsSuccess(true)
        }
        setLoading(false)
      })
      .catch(() => {
        message.error('Samething went wrong')
        setLoading(false)
      })
  }

  const onFinishFailed = (error) => {
    console.log(error)
  }

  const handleOk = () => {
    resetValues()
    navigate('/orders')
  }

  const resetValues = () => {
    setOrder(null)
    setCurrentStep(OrderStepTypeEnum.STEP_CORPORATE)
    setCorporate(null)
    setCatalog(null)
    setProductCatalogs([])
  }

  return (
    <>
      <Content>
        <div className='w-2/3 m-auto m'>
          <div className='px-10'>
            <Steps current={currentStep} items={stepItems} labelPlacement='vertical' />
          </div>
          <div className='steps-content mt-10'>
            <Form
              form={form}
              name='order-form'
              layout='vertical'
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              autoComplete='off'
              requiredMark={false}
              scrollToFirstError
              initialValues={{
                isAcceptFreebie: true,
              }}
            >
              <Spin
                tip='Loading...'
                spinning={loading}
                indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />}
              >
                <div className={`${currentStep !== OrderStepTypeEnum.STEP_CORPORATE && 'hidden'}`}>
                  <StepSelectCorporate form={form} />
                </div>

                <div className={`${currentStep !== OrderStepTypeEnum.STEP_ORDER && 'hidden'}`}>
                  <StepSelectOrder form={form} />
                </div>

                <div className={`${currentStep !== OrderStepTypeEnum.STEP_FREEBIE && 'hidden'}`}>
                  <StepSelectFeebies form={form} />
                </div>

                <div className={`${currentStep !== OrderStepTypeEnum.STEP_ADDRESS && 'hidden'}`}>
                  <StepSelectAddress form={form} />
                </div>

                {currentStep === OrderStepTypeEnum.STEP_SUMMARY && <OrderSummary form={form} />}
              </Spin>
            </Form>
          </div>
        </div>
      </Content>

      <Popup
        title='Quotation Submitted'
        message='The system has successfully uploaded.'
        isOpen={isSuccess}
        okText='Got it'
        onOk={handleOk}
        status='success'
        width={400}
      />
    </>
  )
}

export default CreateOrderPage
