import { OrderStatusTypeEnum } from 'enums/order'

export const isAllowUpdateOrderSummary = (status) => {
  return [
    OrderStatusTypeEnum.QUOTATION_SUBMITTED,
    OrderStatusTypeEnum.QUOTATION_REJECTED,
    OrderStatusTypeEnum.AWAITING_PAYMENT,
  ].includes(status)
}

export const isAllowUpdateOrderCollection = (status) => {
  return [
    OrderStatusTypeEnum.QUOTATION_SUBMITTED,
    OrderStatusTypeEnum.QUOTATION_REJECTED,
    OrderStatusTypeEnum.AWAITING_PAYMENT,

    OrderStatusTypeEnum.PAYMENT_SUBMITTED,
    OrderStatusTypeEnum.PAYMENT_CONFIRMED,
    OrderStatusTypeEnum.PAYMENT_APPROVED,
    OrderStatusTypeEnum.PAYMENT_REJECTED,

    OrderStatusTypeEnum.ORDER_REVIEWED,
    OrderStatusTypeEnum.ORDER_APPROVED,
    OrderStatusTypeEnum.ORDER_REJECTED,

    OrderStatusTypeEnum.INVOICE_SUBMITTED,
    OrderStatusTypeEnum.INVOICE_APPROVED,
    OrderStatusTypeEnum.INVOICE_REJECTED,
  ].includes(status)
}

export const isAllowDownLoadQuotation = (status) => {
  return [
    OrderStatusTypeEnum.QUOTATION_SUBMITTED,
    OrderStatusTypeEnum.AWAITING_PAYMENT,
    OrderStatusTypeEnum.PAYMENT_SUBMITTED,
    OrderStatusTypeEnum.PAYMENT_REJECTED,
    OrderStatusTypeEnum.ORDER_REVIEWED,
  ].includes(status)
}

export const isAllowConfirmPayment = (status) => {
  return OrderStatusTypeEnum.PAYMENT_SUBMITTED === status
}

export const isAllowApprovePayment = (status) => {
  return OrderStatusTypeEnum.PAYMENT_CONFIRMED === status
}

export const isAllowUploadPayment = (status) => {
  return [
    OrderStatusTypeEnum.AWAITING_PAYMENT,
    OrderStatusTypeEnum.PAYMENT_SUBMITTED,
    OrderStatusTypeEnum.PAYMENT_REJECTED,
  ].includes(status)
}

export const isAllowEditUploadPayment = (status) => {
  return [OrderStatusTypeEnum.PAYMENT_SUBMITTED, OrderStatusTypeEnum.PAYMENT_REJECTED].includes(
    status,
  )
}

export const isAllowUploadInvoice = (status) => {
  return [
    OrderStatusTypeEnum.ORDER_APPROVED,
    OrderStatusTypeEnum.INVOICE_SUBMITTED,
    OrderStatusTypeEnum.INVOICE_REJECTED,
  ].includes(status)
}

export const isAllowEditInvoice = (status) => {
  return [OrderStatusTypeEnum.INVOICE_SUBMITTED, OrderStatusTypeEnum.INVOICE_REJECTED].includes(
    status,
  )
}

export const isAllowApproveInvoice = (status) => {
  return OrderStatusTypeEnum.INVOICE_SUBMITTED === status
}

export const isAllowUpdateCardStatus = (status) => {
  return [
    OrderStatusTypeEnum.RECEIPT_UPLOADED,
    OrderStatusTypeEnum.CARD_READY,
    OrderStatusTypeEnum.CARD_RECEIVED,
  ].includes(status)
}

export const isAllowUploadReceipt = (status) => {
  return [OrderStatusTypeEnum.INVOICE_APPROVED, OrderStatusTypeEnum.RECEIPT_UPLOADED].includes(
    status,
  )
}

export const isAllowEditReceipt = (status) => {
  return OrderStatusTypeEnum.RECEIPT_UPLOADED === status
}
