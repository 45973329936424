import dayjs from 'dayjs'
import { Button, Pagination, Table, Tag, Tooltip } from 'antd'
import type { TableRowSelection } from 'antd/es/table/interface'
import { CheckCircleOutlined } from '@ant-design/icons'
import { Paginate } from 'stores/types/common.type'
import { Order } from 'stores/types/order.types'
import { formatNumber } from 'utilities/common'
import { summary } from 'utilities/calculate'
import { PAGE_SIZE_OPTION } from 'constants/common'
import { useAppConfigStore } from 'stores/global'
import { useNavigate } from 'react-router-dom'
import { useEffect, useState } from 'react'
import { OrderStatusTypeEnum } from 'enums/order'
import { ROLE_ACCESS } from 'constants/accessRole'
import { useAuth } from 'stores/authorized'
import { updateOrderStatus } from 'services/order.service'

import PermissionAccess from 'components/PermissionAccess'
import ModalApprove from 'components/common/modal/ModalApprove'

const { Column } = Table
interface IProps {
  loading: boolean
  dataSource: Order[]
  pagination: Paginate
  changePage: (page: number, pageSize: number) => void
  status?: OrderStatusTypeEnum | undefined
  onRefresh?: (selectedRowKeys: any[]) => void
}
const OrderTable = ({
  loading,
  dataSource,
  pagination,
  changePage,
  status = undefined,
}: IProps) => {
  const navigate = useNavigate()
  const { appConfig } = useAppConfigStore()
  const { auth } = useAuth()
  const [rowSelection, setRowSelection] = useState<TableRowSelection<any> | undefined>(undefined)
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([])
  const [isOpenApproval, setIsOpenApproval] = useState<boolean>(false)
  const [isLoading, setIsLoading] = useState<boolean>(loading)

  const hasSelected = selectedRowKeys.length > 0
  const isAllowSubmitPaymentApproved =
    status === OrderStatusTypeEnum.PAYMENT_APPROVED &&
    ROLE_ACCESS.ORDER_LIST.SUBMIT_PAYMENT_APPROVED.includes(auth?.user?.role)
  const isAllowApprovalOrderReviewed =
    status === OrderStatusTypeEnum.ORDER_REVIEWED &&
    ROLE_ACCESS.ORDER_LIST.APPROVE_ORDER_REVIEWED.includes(auth?.user?.role)

  useEffect(() => {
    setIsLoading(loading)
  }, [loading])

  useEffect(() => {
    if (isAllowSubmitPaymentApproved || isAllowApprovalOrderReviewed) {
      setRowSelection({
        selectedRowKeys,
        onChange: onSelectChange,
      })
    } else {
      setRowSelection(undefined)
    }
  }, [status])

  const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
    setSelectedRowKeys(newSelectedRowKeys)
    setRowSelection({
      selectedRowKeys: newSelectedRowKeys,
      onChange: onSelectChange,
    })
  }

  const onClearSelect = () => {
    setRowSelection({
      selectedRowKeys: [],
      onChange: onSelectChange,
    })
    setSelectedRowKeys([])
  }

  const onApprove = () => {
    setIsOpenApproval(true)
  }

  const onChangePage = (page: number, pageSize: number) => {
    changePage(page, pageSize)
    if (isAllowSubmitPaymentApproved || isAllowApprovalOrderReviewed) {
      onClearSelect()
    }
  }

  const onUpdateOrderStatus = async (status: OrderStatusTypeEnum, remark?: string) => {
    setIsLoading(true)
    const payload = {
      orderIds: selectedRowKeys,
      statusToChange: status,
      remark: remark || undefined,
    }
    return await updateOrderStatus(payload)
  }

  const statusChangeTo = () => {
    let statusToChange = OrderStatusTypeEnum.PAYMENT_APPROVED
    if (isAllowSubmitPaymentApproved) {
      statusToChange = OrderStatusTypeEnum.ORDER_REVIEWED
    }
    if (isAllowApprovalOrderReviewed) {
      statusToChange = OrderStatusTypeEnum.ORDER_APPROVED
    }

    return statusToChange
  }

  return (
    <>
      <div className='flex justify-between'>
        <div className='text-xl font-bold'>{formatNumber(pagination.total)} Results found.</div>
        {status === OrderStatusTypeEnum.PAYMENT_APPROVED && (
          <PermissionAccess roles={ROLE_ACCESS.ORDER_LIST.SUBMIT_PAYMENT_APPROVED}>
            <Button type='primary' disabled={!hasSelected} loading={loading} onClick={onApprove}>
              <CheckCircleOutlined className='relative -top-0.5' /> ยืนยันการส่ง (
              {selectedRowKeys?.length})
            </Button>
          </PermissionAccess>
        )}

        {status === OrderStatusTypeEnum.ORDER_REVIEWED && (
          <PermissionAccess roles={ROLE_ACCESS.ORDER_LIST.APPROVE_ORDER_REVIEWED}>
            <div className='flex gap-2'>
              <Button
                type='default'
                disabled={!hasSelected}
                loading={loading}
                onClick={onClearSelect}
              >
                ยกเลิก
              </Button>
              <Button type='primary' disabled={!hasSelected} loading={loading} onClick={onApprove}>
                <CheckCircleOutlined className='relative -top-0.5' /> อนุมัติ (
                {selectedRowKeys?.length})
              </Button>
            </div>
          </PermissionAccess>
        )}
      </div>

      <Table
        loading={isLoading}
        rowSelection={rowSelection}
        pagination={false}
        rowKey={(record) => record?._id}
        dataSource={dataSource}
        className='mt-2 mb-2'
      >
        <Column
          title='Order No.'
          className='break-word column-order-no'
          ellipsis
          render={(record) => record?.orderNumber}
        />

        <Column
          title='Corp. No'
          className='column-crop-no'
          render={(record) => record?.corporate.corporateNumber}
        />
        <Column
          title='Corp. Name'
          className='break-word column-crop-name'
          ellipsis
          render={(record) => (
            <>
              <Tooltip title={record?.corporate.corporateName.th}>
                <div className='text-sm truncate font-bold '>
                  {record?.corporate.corporateName.th}
                </div>
              </Tooltip>
              <Tooltip title={record?.corporate.corporateName.en}>
                <div className='text-sm truncate text-gray-500 '>
                  {record?.corporate.corporateName.en}
                </div>
              </Tooltip>
            </>
          )}
        />
        <Column
          title='Type'
          className='break-word column-type'
          align='center'
          render={(record) => <Tag className='m-auto'>{record?.productCatalogType}</Tag>}
        />
        <Column
          title='Value(฿)'
          className='break-word column-value'
          align='right'
          render={(record) => formatNumber(summary(record?.purchaseTotal, record.customizeTotal))}
        />
        <Column
          title='Collection'
          className='break-word column-collection'
          align='center'
          render={(record) => (
            <Tag color='default' className='m-auto'>
              {record?.deliveryType}
            </Tag>
          )}
        />
        <Column
          title='Status'
          className='break-word column-status'
          align='center'
          render={(record) => (
            <Tag color='processing' className='m-auto list-tag-status'>
              {appConfig.orderStatusEnum[record?.status]}
            </Tag>
          )}
        />

        <Column
          title='Created At'
          className='break-word column-create-at'
          render={(record) => dayjs(record?.createdAt).format('DD MMM YYYY')}
        />
        <Column
          className='column-more-detail'
          render={(item) => (
            <Tooltip title='More Detail'>
              <Button
                type='link'
                className='btn-view'
                onClick={() => navigate(`/order/${item._id}`)}
                automate-test='btn-edit-order'
              >
                View
              </Button>
            </Tooltip>
          )}
        />
      </Table>

      <Pagination
        showSizeChanger
        onChange={onChangePage}
        onShowSizeChange={onChangePage}
        pageSizeOptions={PAGE_SIZE_OPTION}
        pageSize={pagination.limit}
        current={pagination.page}
        total={pagination.total}
        className='text-right'
        locale={{ ['items_per_page']: '' }}
      />

      {!!isOpenApproval && (
        <ModalApprove
          loading={loading}
          isOpen={isOpenApproval}
          onUpdateOrderStatus={onUpdateOrderStatus}
          onCancel={() => setIsOpenApproval(false)}
          statusToChange={statusChangeTo()}
          popupTitle={
            isAllowSubmitPaymentApproved ? 'ยืนยันการส่งเรียบร้อย' : 'ยืนยันการอนุมัติเรียบร้อย'
          }
          popupMessage='กรุณาส่งรายการไปยังขั้นตอนถัดไป'
          modalMessage={`เมื่อคุณยืนยันออเดอร์ทั้งหมด (${selectedRowKeys?.length}) นี้ จะถูกส่งต่อไปยังขั้นตอนถัดไป`}
          modalTitle={isAllowSubmitPaymentApproved ? 'ยืนยันการส่ง' : 'ยืนยันการอนุมัติ'}
          modalApproveButton={isAllowSubmitPaymentApproved ? 'ยืนยันการส่ง' : 'ยืนยันการอนุมัติ'}
        />
      )}
    </>
  )
}

export default OrderTable
