import { useEffect, useState } from 'react'
import { Button, Col, Modal, Row } from 'antd'
import { useOrderStore } from 'stores/order'
import dayjs from 'dayjs'
import { getFileExtension } from 'utilities/common'
import ContentPanel from 'components/common/panel/PanalContent'

interface IProps {
  isOpen: boolean
  onCancel: () => void
  onHandleInvoiceApproveModal: (value: any) => void
  onHandleInvoiceRejectModal: (value: any) => void
}

const ModalInvoiceApproval = ({
  isOpen,
  onCancel,
  onHandleInvoiceApproveModal,
  onHandleInvoiceRejectModal,
}: IProps) => {
  const { order } = useOrderStore()
  const [invoiceSubmitDetail, setInvoiceSubmitDetail] = useState({
    createdBy: '',
    actionDate: '',
    actionTime: '',
    remark: '-',
  })

  useEffect(() => {
    getInvoiceSubmitDetail()
  }, [])

  const getInvoiceSubmitDetail = () => {
    const detail = order?.information?.invoice
    setInvoiceSubmitDetail({
      createdBy: `${detail?.createdBy?.name} (${detail?.createdBy?.employeeId})`,
      actionDate: dayjs(detail?.createdAt).format('DD-MM-YYYY'),
      actionTime: dayjs(detail?.createdAt).format('HH:mm'),
      remark: detail?.invoiceNumber || '-',
    })
  }

  const onInvoiceApproveModal = () => {
    onCancel()
    onHandleInvoiceApproveModal(true)
  }

  const onInvoiceRejectModal = () => {
    onCancel()
    onHandleInvoiceRejectModal(true)
  }

  return (
    <Modal
      title='ตรวจสอบใบแจ้งหนี้'
      className='payment-approval-modal'
      width={680}
      open={isOpen}
      onCancel={onCancel}
      centered
      footer={[
        <Button key='reject' className='reject-modal-btn' onClick={onInvoiceRejectModal}>
          ปฏิเสธใบแจ้งหนี้
        </Button>,
        <Button
          key='submit'
          type='primary'
          className='approve-modal-btn'
          onClick={onInvoiceApproveModal}
        >
          อนุมัติใบแจ้งหนี้
        </Button>,
      ]}
    >
      <ContentPanel shadow={false} className='py-3 px-7 bg-white'>
        <Row gutter={32} className='text-secondary-800 text-base'>
          <Col className='py-1 text-lg font-bold'>ใบแจ้งหนี้ที่รอการตรวจสอบ</Col>
        </Row>
        {!!order?.information?.invoice && (
          <Row gutter={32}>
            <Col className='py-1' span={16}>
              <div className='flex gap-2 items-center'>
                <img
                  src={`/assets/images/icons/icon-${getFileExtension(
                    order?.information?.invoice.originalFileName,
                  )}.svg`}
                  loading='lazy'
                  alt='jpg'
                />
                <a
                  href={order?.information?.invoice.url}
                  target='_blank'
                  rel='noopener noreferrer'
                  className='text-primary underline'
                >
                  {order?.information?.invoice.originalFileName}
                </a>
              </div>
            </Col>
            <Col>
              <Row gutter={[8, 8]}>
                <Col className='py-2' span={12}>
                  อัปโหลดโดย
                </Col>
                <Col className='py-2 text-left' span={12}>
                  {invoiceSubmitDetail.createdBy}
                </Col>
              </Row>
              <Row gutter={[8, 8]}>
                <Col className='py-2' span={12}>
                  วันที่อัปโหลด
                </Col>
                <Col className='py-2 text-left' span={12}>
                  {invoiceSubmitDetail.actionDate}
                </Col>
              </Row>

              <Row gutter={[8, 8]}>
                <Col className='py-2' span={12}>
                  เวลาที่อัปโหลด
                </Col>
                <Col className='py-2 text-left' span={12}>
                  {invoiceSubmitDetail.actionTime}
                </Col>
              </Row>

              <Row gutter={[8, 8]}>
                <Col className='py-2' span={12}>
                  ข้อความ
                </Col>
                <Col className='py-2 text-left' span={12}>
                  {invoiceSubmitDetail.remark || '-'}
                </Col>
              </Row>
            </Col>
          </Row>
        )}
      </ContentPanel>
    </Modal>
  )
}

export default ModalInvoiceApproval
