export enum OrderDeliveryTypeEnum {
  PICKUP = 'PICKUP',
  DELIVERY = 'DELIVERY',
}

export enum OrderStatusTypeEnum {
  QUOTATION_SUBMITTED = 'QUOTATION_SUBMITTED',
  QUOTATION_CANCELED = 'QUOTATION_CANCELED',
  QUOTATION_REJECTED = 'QUOTATION_REJECTED',
  AWAITING_PAYMENT = 'AWAITING_PAYMENT',
  PAYMENT_SUBMITTED = 'PAYMENT_SUBMITTED',
  PAYMENT_CONFIRMED = 'PAYMENT_CONFIRMED',
  PAYMENT_APPROVED = 'PAYMENT_APPROVED',
  PAYMENT_REJECTED = 'PAYMENT_REJECTED',
  ORDER_REVIEWED = 'ORDER_REVIEWED',
  ORDER_APPROVED = 'ORDER_APPROVED',
  ORDER_REJECTED = 'ORDER_REJECTED',
  INVOICE_SUBMITTED = 'INVOICE_SUBMITTED',
  INVOICE_APPROVED = 'INVOICE_APPROVED',
  INVOICE_REJECTED = 'INVOICE_REJECTED',
  RECEIPT_UPLOADED = 'RECEIPT_UPLOADED',
  CARD_READY = 'CARD_READY',
  CARD_RECEIVED = 'CARD_RECEIVED',
  CARD_ACTIVATED = 'CARD_ACTIVATED',
}

export enum ProductCatalogTypeEnum {
  GIFT_CARD = 'GIFT_CARD',
  E_COUPON = 'E_COUPON',
}

export const enum OrderStepTypeEnum {
  STEP_CORPORATE,
  STEP_ORDER,
  STEP_FREEBIE,
  STEP_ADDRESS,
  STEP_SUMMARY,
}

export const enum OrderUploadTypeEnum {
  INVOICE = 'INVOICE',
  RECEIPT = 'RECEIPT',
}
