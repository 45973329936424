import Big from 'big.js'

export const summary = (value: string | number, plusValue: string | number) => {
  return new Big(value).plus(plusValue).toNumber()
}

export const calculateSum = (array: any, property: string): any => {
  if (Array.isArray(array) && array.length) {
    const total = array.reduce((accumulator, object) => {
      return new Big(accumulator).plus(object?.[property] || 0).toNumber()
    }, 0)

    return total
  }
  return 0
}

export const calculateSumItems = (
  array: any,
  field1: string,
  field2: string,
  fieldName = 'total',
): any => {
  if (Array.isArray(array) && array.length) {
    const totalItems = array.map((obj) => {
      return {
        ...obj,
        [fieldName]: new Big(obj?.[field1] || 0).times(obj?.[field2] || 0).toNumber(),
      }
    })
    return totalItems
  }

  return array
}

export const multiply = (field: string | number, property: string | number) => {
  return new Big(field).times(property).toNumber()
}
