import { Button, Col, Form, Input, Row, Alert } from 'antd'
import type { FormInstance } from 'antd/es/form/Form'
import { PlusOutlined, MinusCircleOutlined } from '@ant-design/icons'
import { useEffect, useRef, useState } from 'react'
import { useOrderStore } from 'stores/order'
import { useProductCatalogStore } from 'stores/productCatalog'
import { calculateSum, calculateSumItems, multiply } from 'utilities/calculate'
import { formatNumber } from 'utilities/common'
import { ProductCatalog } from 'stores/types/productCatalog.types'
import { SelectOption } from 'stores/types/common.type'

import ContentPanel from 'components/common/panel/PanalContent'
import NumberInput from 'components/common/input/InputNumber'
import SelectInput from 'components/common/select/Select'
import TableSummaryItems from 'components/features/order/components/TableOrderItems'
import { OrderStepTypeEnum } from 'enums/order'

interface IProps {
  form: FormInstance
}
const StepSelectFeebies = ({ form }: IProps) => {
  const errorRef = useRef<any>(null)
  const { catalog } = useProductCatalogStore()
  const { currentStep, setCurrentStep, order, setOrder, calucateFreebie } = useOrderStore()

  const [freebieTotalCanUse, setFreebieTotalCanUse] = useState<number>(0)
  const [freebiePercentRate, setFreebiePercentRate] = useState<number>(0)
  const [catalogSelectedOptions, setCatalogSelectedOptions] = useState<ProductCatalog | null>()
  const [showError, setShowError] = useState<React.ReactNode | undefined>()

  useEffect(() => {
    if (currentStep === OrderStepTypeEnum.STEP_FREEBIE) {
      if (showError) {
        setShowError(undefined)
      }
      setCatalogSelectedOptions(catalog)
      if (catalog) {
        setFreebieTotalCanUse(calucateFreebie?.totalReceivedFreebie)
        setFreebiePercentRate(multiply(calucateFreebie?.freebieRate, 100))

        const orderItems = calculateSumItems(form.getFieldValue('orderItems'), 'amount', 'quantity')
        const purchaseTotal = calculateSum(orderItems, 'total')
        setOrder({
          ...order,
          freebieRate: calucateFreebie?.freebieRate,
          purchaseTotal,
        })
        if (calucateFreebie?.totalReceivedFreebie === 0) {
          // clear freebieItem, freebieRate and freebieTotal when calucateFreebie return 0
          form.setFieldValue('freebieItems', [])
          setOrder({
            ...order,
            freebieItems: [],
            freebieRate: calucateFreebie.freebieRate,
            freebieTotal: calucateFreebie.totalReceivedFreebie,
          })
          setCurrentStep(OrderStepTypeEnum.STEP_ADDRESS)
        }
      }
    }
  }, [currentStep])

  const filterProductCatalogOptions = () => {
    let finalOptions: SelectOption[] = []
    if (catalogSelectedOptions?.value?.length) {
      const orderItemIds: string[] = form.getFieldValue('freebieItems')?.map((f) => f?._id) ?? []
      finalOptions = catalogSelectedOptions?.value
        .filter((f: { _id: string }) => !orderItemIds.includes(f._id))
        .map((item) => {
          return {
            value: item._id,
            label: `฿${formatNumber(item.amount)}`,
          }
        })
    }
    return finalOptions
  }

  const handleChangeCatalogValue = (selected, index) => {
    const catalogData = catalogSelectedOptions?.value.find((f) => f._id === selected)
    const catalogValue = form.getFieldValue('freebieItems')
    catalogValue[index]._id = catalogData?._id
    catalogValue[index].amount = catalogData?.amount

    form.setFieldValue('freebieItems', catalogValue)
  }

  const handleClickNextStep = async () => {
    let freebieItems = form.getFieldValue('freebieItems')
    const validateOrderItemFields = freebieItems.map((_, index) => {
      return [
        ['freebieItems', index, '_id'],
        ['freebieItems', index, 'amount'],
        ['freebieItems', index, 'quantity'],
      ]
    })
    const validateNameList = [
      'productCatalogId',
      'productCatalogType',
      ...validateOrderItemFields.flat(),
    ]
    const formValues = await form.validateFields(validateNameList)
    if (formValues) {
      freebieItems = calculateSumItems(freebieItems, 'amount', 'quantity')
      const freebieTotal = calculateSum(freebieItems, 'total')
      if (freebieTotal !== freebieTotalCanUse) {
        setShowError(
          <Alert
            description='กรุณาเลือกของแถมให้มีมูลค่าเท่ากับที่กำหนด'
            type='error'
            showIcon
            className='rounded-lg border-none'
          />,
        )
        errorRef.current.scrollIntoView({ behavior: 'smooth' })
        return
      }

      setOrder(formValues)

      if (order?._id) {
        form.submit()
      } else {
        setCurrentStep(OrderStepTypeEnum.STEP_ADDRESS)
      }
    }
  }

  const disabledNextStep = () => {
    return !form.getFieldValue('freebieItems')?.length
  }

  const onShowError = () => {
    if (showError) {
      setShowError(undefined)
    }
  }

  return (
    <>
      <ContentPanel shadow={false} className='bg-secondary'>
        <Row gutter={16} className='text-secondary-500 text-lg'>
          <Col className='py-2' span={16}>
            Get % on top of purchase
          </Col>
          <Col className='py-2 text-right' span={8} automate-test='freebie-percentage'>
            {formatNumber(freebiePercentRate)}%
          </Col>
        </Row>

        <Row
          gutter={16}
          className='border-t border-solid border-secondary-300 text-lg font-semibold mt-4'
        >
          <Col className='py-3' span={16}>
            Receive freebies up to
          </Col>
          <Col className='py-3 text-right' span={8} automate-test='freebie-total-receive'>
            THB: {formatNumber(freebieTotalCanUse, 2)}
          </Col>
        </Row>
      </ContentPanel>

      <div ref={errorRef}></div>
      <ContentPanel title='Select Giftcard Value' error={showError}>
        <Form.Item noStyle shouldUpdate>
          {({ getFieldValue }) => (
            <Form.List name='freebieItems'>
              {(fields, { add, remove }) => (
                <>
                  <div className='flex justify-end'>
                    <Button
                      type='default'
                      size='large'
                      icon={<PlusOutlined />}
                      onClick={() => add()}
                      disabled={!getFieldValue('productCatalogId')}
                      automate-test='btn-add-freebie-item'
                    >
                      Add Item
                    </Button>
                  </div>

                  <Row
                    gutter={16}
                    className='border-b border-solid boder-secondary text-secondary-500 mb-4'
                  >
                    <Col className='py-3' span={4}>
                      #
                    </Col>
                    <Col className='py-3' span={13}>
                      Value
                    </Col>
                    <Col className='py-3' span={5}>
                      Quantity
                    </Col>
                    <Col className='py-3' span={2}></Col>
                  </Row>

                  {fields.map(({ key, name, ...restField }, index) => (
                    <Row gutter={16} key={key} align='middle' className='mb-4'>
                      <Col span={4}>
                        {index + 1}

                        <Form.Item {...restField} name={[name, '_id']} hidden>
                          <Input hidden />
                        </Form.Item>
                      </Col>

                      <Col span={13}>
                        <Form.Item
                          {...restField}
                          name={[name, 'amount']}
                          rules={[{ required: true, message: 'please select card value' }]}
                          className='m-0'
                          help=''
                          hasFeedback
                          validateStatus={showError ? 'error' : ''}
                        >
                          <SelectInput
                            options={filterProductCatalogOptions()}
                            onChange={(value) => handleChangeCatalogValue(value, index)}
                            placeholder='Card value'
                            attr={`select-freebie-card-value-${index}`}
                          />
                        </Form.Item>
                      </Col>

                      <Col span={5}>
                        <Form.Item
                          {...restField}
                          name={[name, 'quantity']}
                          rules={[
                            {
                              required: true,
                              message: 'Please input quantity.',
                              type: 'number',
                            },
                          ]}
                          className='m-0'
                          help=''
                          hasFeedback
                          validateStatus={showError ? 'error' : ''}
                        >
                          <NumberInput
                            placeholder='Qty.'
                            onChange={onShowError}
                            attr={`input-freebie-card-quantity-${index}`}
                          />
                        </Form.Item>
                      </Col>
                      <Col span={2}>
                        <MinusCircleOutlined
                          onClick={() => remove(name)}
                          automate-test={`btn-delete-freebie-item-${index}`}
                        />
                      </Col>
                    </Row>
                  ))}
                </>
              )}
            </Form.List>
          )}
        </Form.Item>
      </ContentPanel>

      <ContentPanel shadow={false} className='bg-secondary'>
        <div id='table-summary-freebie'>
          <Form.Item noStyle shouldUpdate>
            {({ getFieldValue }) => {
              return (
                <TableSummaryItems
                  items={getFieldValue('freebieItems')}
                  catalogName={catalog?.type.fullName}
                  notFund='No freebie selected'
                />
              )
            }}
          </Form.Item>
        </div>
      </ContentPanel>

      <Form.Item shouldUpdate>
        {() => {
          return (
            <div className='mt-10 flex gap-2 justify-center'>
              <Button
                type='default'
                size='large'
                onClick={() => setCurrentStep(OrderStepTypeEnum.STEP_ORDER)}
                automate-test='btn-back-step-freebie'
              >
                Back
              </Button>

              <Button
                type='primary'
                size='large'
                onClick={handleClickNextStep}
                disabled={disabledNextStep()}
                automate-test='btn-save-step-freebie'
              >
                {order?._id ? 'Save Changes' : 'Next'}
              </Button>
            </div>
          )
        }}
      </Form.Item>
    </>
  )
}

export default StepSelectFeebies
