import { useEffect, useState } from 'react'
import { Tabs } from 'antd'
import { useParams } from 'react-router-dom'
import { getOrderById, getOrderSignUrl } from 'services/order.service'
import { getProductCatalogฺById } from 'services/productCatalog.service'
import { useGlobalStore } from 'stores/global'
import { useOrderStore } from 'stores/order'
import { useProductCatalogStore } from 'stores/productCatalog'

import OrderActivities from 'components/features/order/tabs/OrderActivities'
import OrderSummaries from 'components/features/order/tabs/OrderSummaries'

const DetailOrderPage = () => {
  const param = useParams()
  const { setPageHeader } = useGlobalStore()
  const { order, setOrder } = useOrderStore()
  const { setCatalog } = useProductCatalogStore()

  const [tabItems, setTabItems] = useState<any>([])
  useEffect(() => {
    fetchOrderById(param?.orderId)
  }, [])

  useEffect(() => {
    if (order?._id) {
      fetchOrderSignUrl(param?.orderId)
    }
  }, [order?._id])

  const fetchOrderById = async (id) => {
    try {
      const payload = await getOrderById(id)
      if (payload.status?.code === 200) {
        fetchProductCatalogById(payload.data?.productCatalogId)
        setPageHeader({
          title: `Order > ${payload.data?.orderNumber}`,
          subTitle: undefined,
          onBack: '/orders',
          backIcon: null,
          extra: [],
        })
        setOrder({
          ...payload.data,
          corporateId: payload.data?.corporate?._id,
        })
        setTabItems([
          { label: 'Activities', key: 'item-1', children: <OrderActivities orderId={id} /> },
          { label: 'Order Summary', key: 'item-2', children: <OrderSummaries /> },
        ])
      }
    } catch (error) {
      console.log(error)
    }
  }

  const fetchProductCatalogById = async (productCatalogId) => {
    const payload = await getProductCatalogฺById(productCatalogId)
    if (payload?.status.code === 200) {
      setCatalog(payload?.data)
    }
  }

  const fetchOrderSignUrl = async (id) => {
    try {
      const payload = await getOrderSignUrl(id)
      if (payload.status?.code === 200) {
        setOrder({
          information: {
            ...order?.information,
            receipt: payload.data?.receipt,
            invoice: payload.data?.invoice
              ? {
                  ...payload.data?.invoice,
                  invoiceNumber: order?.information?.invoice?.invoiceNumber,
                }
              : payload.data?.invoice,
          },
          payment: payload.data?.payment,
        })
      }
    } catch (error) {
      console.log(error)
    }
  }

  return (
    <>
      <div className='m-6'>
        <Tabs items={tabItems} size='large' />
      </div>
    </>
  )
}

export default DetailOrderPage
